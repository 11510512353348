var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container productionOrder" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productionOrderCode,
                        callback: function($$v) {
                          _vm.productionOrderCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "productionOrderCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("运单号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.waybillNo,
                        callback: function($$v) {
                          _vm.waybillNo =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "waybillNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产订单类型：")
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.productionOrderType,
                          callback: function($$v) {
                            _vm.productionOrderType =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "productionOrderType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.materialClassList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.name }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产订单状态：")
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.productionOrderStatus,
                          callback: function($$v) {
                            _vm.productionOrderStatus =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "productionOrderStatus"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.materialStatusList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      staticClass: "mr10",
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.produceList,
                              "row-key": "id",
                              "expand-row-keys": _vm.expands,
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.moDeliverOrderDetailListItem
                                          .length != 0
                                          ? _c(
                                              "el-table",
                                              {
                                                ref:
                                                  "childMultipleTable_" +
                                                  scope.row.id,
                                                refInFor: true,
                                                staticClass: "table_list",
                                                attrs: {
                                                  data:
                                                    scope.row
                                                      .moDeliverOrderDetailListItem,
                                                  "header-cell-style": {
                                                    background:
                                                      "rgb(104 194 58 / 0.3)"
                                                  },
                                                  border: "",
                                                  fit: ""
                                                }
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: { label: "发货单号" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.deliverCode
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "创建时间" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.createTime
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "创建人" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.applicant
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "发货地址" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.receiverAddress
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "发货状态" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.transportStatus
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "运单号" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.transportNumber
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "凭证状态" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _c(
                                                              "el-tag",
                                                              {
                                                                staticClass:
                                                                  "pointer",
                                                                attrs: {
                                                                  slot:
                                                                    "reference",
                                                                  type: _vm._f(
                                                                    "statusFilter"
                                                                  )(
                                                                    row.stStatus
                                                                  )
                                                                },
                                                                slot:
                                                                  "reference"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      row.moDeliverOrderStatusName
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "操作" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          var $index =
                                                            ref.$index
                                                          return [
                                                            row.moDeliverOrderStatus ===
                                                            "8"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon-edit",
                                                                        attrs: {
                                                                          disabled:
                                                                            row.childcloseStatus ==
                                                                            "2",
                                                                          type:
                                                                            "primary",
                                                                          circle:
                                                                            "",
                                                                          size:
                                                                            "mini",
                                                                          title:
                                                                            "编辑"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.editHandle(
                                                                              $index,
                                                                              row,
                                                                              "fahuodan"
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon-check",
                                                                        attrs: {
                                                                          disabled:
                                                                            row.childcloseStatus ==
                                                                            "2",
                                                                          type:
                                                                            "success",
                                                                          circle:
                                                                            "",
                                                                          size:
                                                                            "mini",
                                                                          title:
                                                                            "确认"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.submitHandle(
                                                                              $index,
                                                                              row,
                                                                              "singlesubmit"
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon-delete",
                                                                        attrs: {
                                                                          disabled:
                                                                            row.childcloseStatus ==
                                                                            "2",
                                                                          type:
                                                                            "danger",
                                                                          circle:
                                                                            "",
                                                                          size:
                                                                            "mini",
                                                                          title:
                                                                            "删除"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.deleteHandle(
                                                                              $index,
                                                                              row,
                                                                              "del"
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            row.moDeliverOrderStatus ===
                                                            "2"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticClass:
                                                                          "el-icon-document",
                                                                        attrs: {
                                                                          type:
                                                                            "primary",
                                                                          circle:
                                                                            "",
                                                                          size:
                                                                            "mini",
                                                                          title:
                                                                            "查看详情"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.editHandle(
                                                                              $index,
                                                                              row,
                                                                              "seedetail"
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        scope.row.inDetailList.length != 0
                                          ? _c(
                                              "el-table",
                                              {
                                                ref:
                                                  "childMultipleTable_" +
                                                  scope.row.id,
                                                refInFor: true,
                                                staticClass: "table_list mt20",
                                                attrs: {
                                                  "header-cell-style": {
                                                    background:
                                                      "rgb(64 158 255 / 30%)"
                                                  },
                                                  data: scope.row.inDetailList,
                                                  border: "",
                                                  fit: ""
                                                }
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: { label: "入库单号" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(row.uuid)
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "创建时间" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.createTime
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "创建人" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.createUser
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "入库类型" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.voucherTypeName
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                }),
                                                _c("el-table-column", {
                                                  attrs: { label: "操作" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          var $index =
                                                            ref.$index
                                                          return [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticClass:
                                                                      "el-icon-document",
                                                                    attrs: {
                                                                      type:
                                                                        "primary",
                                                                      circle:
                                                                        "",
                                                                      size:
                                                                        "mini",
                                                                      title:
                                                                        "查看详情"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.editHandleIn(
                                                                          $index,
                                                                          row
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "生产订单编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(_vm._s(row.productionOrderCode))
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "生产类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(_vm._s(row.productionType))
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "用途" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [_vm._v(_vm._s(row.purpose))]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [_vm._v(_vm._s(row.createTime))]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [_vm._v(_vm._s(row.applicantName))]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-tag",
                                          {
                                            staticClass: "pointer",
                                            attrs: {
                                              slot: "reference",
                                              type: _vm._f("statusFilter")(
                                                row.stStatus
                                              )
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v(_vm._s(row.stStatusName))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.stStatus == "Approved"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-truck",
                                                  attrs: {
                                                    disabled:
                                                      row.closeStatus == "2",
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "发货"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row,
                                                        "fahuo"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    disabled:
                                                      row.closeStatus == "2",
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.closeHandle(
                                                        $index,
                                                        row,
                                                        "close"
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                }
              ],
              staticClass: "edit_part"
            },
            [
              _c("div", { staticClass: "clearfix mb10" }, [
                _vm.isSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("发货单号：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.deliverCodefh))
                      ])
                    ])
                  : _vm._e(),
                _vm.isSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("创建时间：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.createTimefh))
                      ])
                    ])
                  : _vm._e(),
                _vm.isSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("创建人：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.purpose))
                      ])
                    ])
                  : _vm._e(),
                !_vm.isInSeedetail
                  ? _c(
                      "div",
                      { staticClass: "inline_block mr10 mb10" },
                      [
                        _vm.isSeedetail
                          ? _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                              _vm._v("省份：")
                            ])
                          : _vm._e(),
                        _vm.isSeedetail
                          ? _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                              _vm._v(_vm._s(_vm.province))
                            ])
                          : _vm._e(),
                        _vm.isSeedetail
                          ? _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                              _vm._v("城市：")
                            ])
                          : _vm._e(),
                        _vm.isSeedetail
                          ? _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                              _vm._v(_vm._s(_vm.city))
                            ])
                          : _vm._e(),
                        !_vm.isSeedetail
                          ? _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                              _vm._v("省市区：")
                            ])
                          : _vm._e(),
                        !_vm.isSeedetail
                          ? _c("el-cascader", {
                              attrs: {
                                size: "large",
                                options: _vm.regionOptions
                              },
                              on: { change: _vm.regionCascaderChange },
                              model: {
                                value: _vm.region,
                                callback: function($$v) {
                                  _vm.region = $$v
                                },
                                expression: "region"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isInSeedetail
                  ? _c(
                      "div",
                      { staticClass: "display_ib mr10" },
                      [
                        _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                          _vm._v("发货地址：")
                        ]),
                        _vm.isSeedetail
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-width": "1100px",
                                  display: "inline-block"
                                }
                              },
                              [_vm._v(_vm._s(_vm.receiverAddress))]
                            )
                          : _vm._e(),
                        !_vm.isSeedetail
                          ? _c("el-input", {
                              staticClass: "input_single w200 mr10 mb10",
                              staticStyle: { width: "500px !important" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.receiverAddress,
                                callback: function($$v) {
                                  _vm.receiverAddress =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "receiverAddress"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("运单号：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.transportNumber))
                      ])
                    ])
                  : _vm._e(),
                _vm.isSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("备注：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.remarksfh))
                      ])
                    ])
                  : _vm._e(),
                !_vm.isInSeedetail
                  ? _c(
                      "div",
                      { staticClass: "display_ib mr10" },
                      [
                        _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                          _vm._v("联系人：")
                        ]),
                        _vm.isSeedetail
                          ? _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                              _vm._v(_vm._s(_vm.receiver))
                            ])
                          : _vm._e(),
                        !_vm.isSeedetail
                          ? _c("el-input", {
                              staticClass: "input_single w200 mr10 mb10",
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.receiver,
                                callback: function($$v) {
                                  _vm.receiver =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "receiver"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isInSeedetail
                  ? _c(
                      "div",
                      { staticClass: "display_ib mr10" },
                      [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("联系人电话：")
                        ]),
                        _vm.isSeedetail
                          ? _c("span", { staticClass: "tag" }, [
                              _vm._v(_vm._s(_vm.receiverContactPhone))
                            ])
                          : _vm._e(),
                        !_vm.isSeedetail
                          ? _c("el-input", {
                              staticClass: "input_single w200 mr10 mb10",
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.receiverContactPhone,
                                callback: function($$v) {
                                  _vm.receiverContactPhone =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "receiverContactPhone"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isInSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("入库单号：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.deliverCoderk))
                      ])
                    ])
                  : _vm._e(),
                _vm.isInSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("创建时间：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.createTimerk))
                      ])
                    ])
                  : _vm._e(),
                _vm.isInSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("创建人：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.createUserrk))
                      ])
                    ])
                  : _vm._e(),
                _vm.isInSeedetail
                  ? _c("div", { staticClass: "display_ib mr10" }, [
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v("入库类型：")
                      ]),
                      _c("span", { staticClass: "tag w200 mr10 mb10" }, [
                        _vm._v(_vm._s(_vm.voucherTypeNamerk))
                      ])
                    ])
                  : _vm._e(),
                !_vm.isInSeedetail && !_vm.isSeedetail
                  ? _c(
                      "a",
                      {
                        staticClass: "link inline_block lh36 fr ml10",
                        attrs: { type: "primary" },
                        on: { click: _vm.exportExcelTemplate }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.disabledbo == true
                              ? "面料转成衣导入模板"
                              : _vm.isMaterialsTransform == true
                              ? "物料转号导入模板"
                              : "面料转样本画册导入模板"
                          )
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              !_vm.isSeedetail && !_vm.isInSeedetail
                ? _c(
                    "div",
                    [
                      _c("upload-excel-component", {
                        staticClass: "mb20",
                        attrs: {
                          "on-success": _vm.importExcelSuccess,
                          "before-upload": _vm.importExcelBeforeUpload
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "edit_wrap" },
                        [
                          _c("editDoubleTable", {
                            attrs: {
                              tableList: _vm.editList,
                              tableLeaderFieldsList: _vm.leaderFieldsList,
                              tableTailFieldsList: _vm.tailFieldsList,
                              tableFieldsList: _vm.editFieldsList
                            },
                            on: {
                              inputChange: _vm.inputChange,
                              selectChange: _vm.selectChange,
                              selectVisibleChange: _vm.selectVisibleChange,
                              datePickerChange: _vm.datePickerChange,
                              numberInputChange: _vm.numberInputChange,
                              deleteChlidHandle: _vm.deleteEditItemHandle,
                              addHandle: _vm.addEditItemHandle
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "mt20 fr" },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.cancelEditHandle } },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "preventReClick",
                                      rawName: "v-preventReClick"
                                    }
                                  ],
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveEditHandle("save")
                                    }
                                  }
                                },
                                [_vm._v("保存")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "preventReClick",
                                      rawName: "v-preventReClick"
                                    }
                                  ],
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveEditHandleconfirm("submit")
                                    }
                                  }
                                },
                                [_vm._v("确认")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isSeedetail
                ? _c(
                    "div",
                    { staticClass: "edit_wrap" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table_list",
                          attrs: { data: _vm.editListfh, border: "", fit: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "行号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.lineNum))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "生产物料编号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(_vm._s(row.productionMaterialCode))
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "原材料产品编号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.sourceProductCode))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "原材料物料编号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(_vm._s(row.sourceMaterialCode))
                                  ]
                                }
                              }
                            ])
                          }),
                          !_vm.disabledbo && !_vm.isMaterialsTransform
                            ? _c("el-table-column", {
                                attrs: { label: "页码" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [_vm._v(_vm._s(row.pageNumber))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1057627663
                                )
                              })
                            : _vm._e(),
                          !_vm.disabledbo && !_vm.isMaterialsTransform
                            ? _c("el-table-column", {
                                attrs: { label: "位置" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [_vm._v(_vm._s(row.position))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1992779366
                                )
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: { label: "原材料发货数量" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      _vm._s(row.deliveryQuantityMaterials) +
                                        " " +
                                        _vm._s(row.purchaseMeasurementUnit)
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "出库仓位" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.inventoryName))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "出库库位" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.subInventoryName))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "备注" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.remarks))]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt20 fr" },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.cancelEditHandle } },
                            [_vm._v("关闭")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isInSeedetail && _vm.voucherTypeNamerk != "生产入库"
                ? _c(
                    "div",
                    { staticClass: "edit_wrap" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table_list",
                          attrs: { data: _vm.editListrk, border: "", fit: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "行号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.lineNumber))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "原材料物料编号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.materialCode))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "原材料退回数量" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      _vm._s(row.receiptQuantity) +
                                        " " +
                                        _vm._s(row.purchaseMeasurementUnit)
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "入库仓位" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.inventoryName))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "入库库位" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(_vm._s(row.inSubInventoryName))
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "备注" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.remarks))]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt20 fr" },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.cancelEditHandle } },
                            [_vm._v("关闭")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isInSeedetail && _vm.voucherTypeNamerk == "生产入库"
                ? _c(
                    "div",
                    { staticClass: "edit_wrap" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table_list",
                          attrs: { data: _vm.editListrkp, border: "", fit: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "行号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.lineNumber))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "物料编号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.materialCode))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "物料数量" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      _vm._s(row.receiptQuantity) +
                                        " " +
                                        _vm._s(row.purchaseMeasurementUnit)
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "入库仓位" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.inventoryName))]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "入库库位" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(_vm._s(row.inSubInventoryName))
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "备注" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.remarks))]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt20 fr" },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.cancelEditHandle } },
                            [_vm._v("关闭")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.allFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.checkProduceList.sampleStoriList, function(
                    childItem,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index, staticClass: "list_group" },
                      _vm._l(childItem.sampleList, function(
                        simpleItem,
                        simpleIndex
                      ) {
                        return _c(
                          "ul",
                          { key: simpleIndex, staticClass: "childcontentlist" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.sourceProductCode }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.sourceProductCode
                                        ? simpleItem.sourceProductCode
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.sourceMaterialCode }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.sourceMaterialCode
                                        ? simpleItem.sourceMaterialCode
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.materialType }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.materialType
                                        ? simpleItem.materialType
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.sourceQuantity }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.sourceQuantity
                                        ? simpleItem.sourceQuantity
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.supplierName }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.supplierName
                                        ? simpleItem.supplierName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            simpleIndex === 0
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "w6p",
                                    attrs: { title: childItem.requiredDate }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          childItem.requiredDate
                                            ? childItem.requiredDate
                                            : ""
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            simpleIndex === 0
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "w6p",
                                    attrs: {
                                      title: childItem.productionMaterialCode
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        childItem.productionMaterialCode
                                          ? childItem.productionMaterialCode
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            simpleIndex === 0
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "w6p",
                                    attrs: {
                                      title: childItem.productionQuantity
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        childItem.productionQuantity
                                          ? childItem.productionQuantity
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            simpleIndex !== 0
                              ? _c("li", { staticClass: "w6p" })
                              : _vm._e(),
                            simpleIndex !== 0
                              ? _c("li", { staticClass: "w6p" })
                              : _vm._e(),
                            simpleIndex !== 0
                              ? _c("li", { staticClass: "w6p" })
                              : _vm._e(),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: {
                                  title: simpleItem.outVirtualInventoryName
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.outVirtualInventoryName
                                        ? simpleItem.outVirtualInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.outSubInventoryName }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.outSubInventoryName
                                        ? simpleItem.outSubInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: {
                                  title: simpleItem.inVirtualInventoryName
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.inVirtualInventoryName
                                        ? simpleItem.inVirtualInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.inSubInventoryName }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.inSubInventoryName
                                        ? simpleItem.inSubInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.inventoryInNum }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.inventoryInNum
                                        ? simpleItem.inventoryInNum
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.confirmNo }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.confirmNo
                                      ? simpleItem.confirmNo
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.remark }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.remark ? simpleItem.remark : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.auditOption }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.auditOption
                                      ? simpleItem.auditOption
                                      : ""
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.deleteDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.closeDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.closeDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定关闭？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.closeDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmCloseHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.submitDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.submitDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定提交确认？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.submitDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSubmitHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
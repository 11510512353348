<template>
  <div class="container productionOrder">
    <div class="part" v-if="!isShowEdit">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">生产订单编号：</span>
            <el-input
              class="input_single w200 mr10"
              v-model.trim="productionOrderCode"
              placeholder="请输入"
              @keyup.enter.native="searchHandle"
            ></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">运单号：</span>
            <el-input class="input_single w200 mr10" v-model.trim="waybillNo" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">生产订单类型：</span>
            <el-select
              class="select_single w200 mr10"
              v-model.trim="productionOrderType"
              @change="searchHandle"
              placeholder="请选择">
              <el-option key label="全部" value></el-option>
              <el-option
                v-for="item in materialClassList"
                :key="item.index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">生产订单状态：</span>
            <el-select
              class="select_single w200 mr10"
              v-model.trim="productionOrderStatus"
              @change="searchHandle"
              placeholder="请选择">
              <el-option key label="全部" value></el-option>
              <el-option
                v-for="item in materialStatusList"
                :key="item.index"
                :label="item.name"
                :value="item.index"
              ></el-option>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">创建日期：</span>
            <el-date-picker
              class="mr10"
              v-model.trim="dateVal"
              unlink-panels
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateValChange"
            ></el-date-picker>
          </div>
        </div>
        <div class="actions_btn_wrap down t_right">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          ></el-button>
        </div>
      </div>

      <div class="table_part clearfix">
        <el-tabs class="subnav_wrap" v-model="activeName" @tab-click="switchTabHandle">
          <el-tab-pane
            v-for="item in subNavList"
            :key="item.value"
            :label="item.label"
            :name="item.value"
            v-loading="tableLoading">
            <el-table
              class="table_list"
              ref="multipleTable"
              :data="produceList"
              row-key="id"
              :expand-row-keys="expands"
              @row-click="rowClick"
              @expand-change="rowClick"
              border
              fit
              stripe>
              <!-- <el-table-column type="selection" /> -->
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <!-- 发货单 -->
                  <el-table
                    class="table_list"
                    :ref="'childMultipleTable_' + scope.row.id"
                    :data="scope.row.moDeliverOrderDetailListItem"
                    v-if="scope.row.moDeliverOrderDetailListItem.length!= 0"
                    :header-cell-style="{background:'rgb(104 194 58 / 0.3)'}"
                    border
                    fit>
                    <el-table-column label="发货单号">
                      <template slot-scope="{ row }">{{ row.deliverCode }}</template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                      <template slot-scope="{ row }">{{ row.createTime }}</template>
                    </el-table-column>
                    <el-table-column label="创建人">
                      <template slot-scope="{ row }">{{ row.applicant }}</template>
                    </el-table-column>
                    <el-table-column label="发货地址">
                      <template slot-scope="{ row }">{{ row.receiverAddress }}</template>
                    </el-table-column>
                    <el-table-column label="发货状态">
                      <template slot-scope="{ row }">{{ row.transportStatus }}</template>
                    </el-table-column>
                    <el-table-column label="运单号">
                      <template slot-scope="{ row }">{{ row.transportNumber }}</template>
                    </el-table-column>
                    <el-table-column label="凭证状态">
                      <template slot-scope="{ row }">
                        <el-tag
                          slot="reference"
                          class="pointer"
                          :type="row.stStatus | statusFilter">
                          {{ row.moDeliverOrderStatusName }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="{ row, $index }">
                        <!-- 草稿 -->
                        <div v-if="row.moDeliverOrderStatus === '8'">
                          <el-button
                            :disabled="row.childcloseStatus == '2'"
                            class="el-icon-edit"
                            type="primary"
                            circle
                            size="mini"
                            title="编辑"
                            @click.stop="editHandle($index, row,'fahuodan')"
                          ></el-button>
                          <el-button
                            :disabled="row.childcloseStatus == '2'"
                            class="el-icon-check"
                            type="success"
                            circle
                            size="mini"
                            title="确认"
                            @click.stop="submitHandle($index, row,'singlesubmit')"
                          ></el-button>
                          <el-button
                            :disabled="row.childcloseStatus == '2'"
                            class="el-icon-delete"
                            type="danger"
                            circle
                            size="mini"
                            title="删除"
                            @click.stop="deleteHandle($index, row,'del')"
                          ></el-button>
                        </div>
                        <!-- 已确认 -->
                        <div v-if="row.moDeliverOrderStatus === '2'">
                          <el-button
                            class="el-icon-document"
                            type="primary"
                            circle
                            size="mini"
                            title="查看详情"
                            @click.stop="editHandle($index, row,'seedetail')"
                          ></el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>

                  <!-- 入库单 -->
                  <el-table
                    class="table_list mt20"
                    :ref="'childMultipleTable_' + scope.row.id"
                    :header-cell-style="{background:'rgb(64 158 255 / 30%)'}"
                    :data="scope.row.inDetailList"
                    v-if="scope.row.inDetailList.length!= 0"
                    border
                    fit>
                    <el-table-column label="入库单号">
                      <template slot-scope="{ row }">{{ row.uuid }}</template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                      <template slot-scope="{ row }">{{ row.createTime }}</template>
                    </el-table-column>
                    <el-table-column label="创建人">
                      <template slot-scope="{ row }">{{ row.createUser }}</template>
                    </el-table-column>
                    <el-table-column label="入库类型">
                      <template slot-scope="{ row }">{{ row.voucherTypeName }}</template>
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="{ row, $index }">
                        <!-- v-if="row.stStatus == 'Draft' || row.stStatus == 'Rejected' -->
                        <div>
                          <el-button
                            class="el-icon-document"
                            type="primary"
                            circle
                            size="mini"
                            title="查看详情"
                            @click.stop="editHandleIn($index, row)"
                          ></el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column label="生产订单编号">
                <template slot-scope="{ row }">{{ row.productionOrderCode }}</template>
              </el-table-column>
              <el-table-column label="生产类型">
                <template slot-scope="{ row }">{{ row.productionType }}</template>
              </el-table-column>
              <el-table-column label="用途">
                <template slot-scope="{ row }">{{ row.purpose }}</template>
              </el-table-column>
              <el-table-column label="创建时间">
                <template slot-scope="{ row }">{{ row.createTime }}</template>
              </el-table-column>
              <el-table-column label="申请人">
                <template slot-scope="{ row }">{{ row.applicantName }}</template>
              </el-table-column>
              <el-table-column label="凭证状态">
                <template slot-scope="{ row }">
                  <el-tag
                    slot="reference"
                    class="pointer"
                    :type="row.stStatus | statusFilter"
                  >{{ row.stStatusName }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row, $index }">
                  <div v-if="row.stStatus == 'Approved'">
                    <el-button
                      :disabled="row.closeStatus == '2'"
                      class="el-icon-truck"
                      type="primary"
                      circle
                      size="mini"
                      title="发货"
                      @click.stop="editHandle($index, row,'fahuo')"
                    ></el-button>
                    <el-button
                      :disabled="row.closeStatus == '2'"
                      class="el-icon-switch-button"
                      type="danger"
                      circle
                      size="mini"
                      title="关闭"
                      @click.stop="closeHandle($index, row,'close')"
                    ></el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>

        <div class="pagination_wrap fr">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 50]"
            :page-size="pageSize"
            layout="sizes, total, prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 编辑页 -->
    <div class="edit_part" v-loading="editPartLoading" v-else>
      <div class="clearfix mb10">
        <div v-if="isSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">发货单号：</span>
          <span class="tag w200 mr10 mb10">{{deliverCodefh}}</span>
        </div>
        <div v-if="isSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">创建时间：</span>
          <span class="tag w200 mr10 mb10">{{createTimefh}}</span>
        </div>
        <div v-if="isSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">创建人：</span>
          <span class="tag w200 mr10 mb10">{{purpose}}</span>
        </div>
        <div v-if="!isInSeedetail" class="inline_block mr10 mb10">
          <span v-if="isSeedetail" class="tag w200 mr10 mb10">省份：</span>
          <span v-if="isSeedetail" class="tag w200 mr10 mb10">{{province}}</span>
          <span v-if="isSeedetail" class="tag w200 mr10 mb10">城市：</span>
          <span v-if="isSeedetail" class="tag w200 mr10 mb10">{{city}}</span>

          <span v-if="!isSeedetail" class="tag w200 mr10 mb10">省市区：</span>
          <el-cascader
            v-if="!isSeedetail"
            size="large"
            :options="regionOptions"
            v-model="region"
            @change="regionCascaderChange">
          </el-cascader>
        </div>
        <div v-if="!isInSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">发货地址：</span>
          <div
            v-if="isSeedetail"
            style="max-width: 1100px;display: inline-block;"
          >{{receiverAddress}}</div>
          <el-input
            v-if="!isSeedetail"
            style="width:500px !important"
            class="input_single w200 mr10 mb10"
            v-model.trim="receiverAddress"
            placeholder="请输入"
          ></el-input>
        </div>
        <div v-if="isSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">运单号：</span>
          <span class="tag w200 mr10 mb10">{{transportNumber}}</span>
        </div>
        <div v-if="isSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">备注：</span>
          <span class="tag w200 mr10 mb10">{{remarksfh}}</span>
        </div>

        <div v-if="!isInSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">联系人：</span>
          <span v-if="isSeedetail" class="tag w200 mr10 mb10">{{receiver}}</span>
          <el-input
            v-if="!isSeedetail"
            class="input_single w200 mr10 mb10"
            v-model.trim="receiver"
            placeholder="请输入"
          ></el-input>
        </div>
        <div v-if="!isInSeedetail" class="display_ib mr10">
          <span class="tag">联系人电话：</span>
          <span v-if="isSeedetail" class="tag">{{receiverContactPhone}}</span>
          <el-input
            v-if="!isSeedetail"
            class="input_single w200 mr10 mb10"
            v-model.trim="receiverContactPhone"
            placeholder="请输入"
          ></el-input>
        </div>

        <!-- 入库部分 -->
        <div v-if="isInSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">入库单号：</span>
          <span class="tag w200 mr10 mb10">{{deliverCoderk}}</span>
        </div>
        <div v-if="isInSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">创建时间：</span>
          <span class="tag w200 mr10 mb10">{{createTimerk}}</span>
        </div>
        <div v-if="isInSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">创建人：</span>
          <span class="tag w200 mr10 mb10">{{createUserrk}}</span>
        </div>
        <div v-if="isInSeedetail" class="display_ib mr10">
          <span class="tag w200 mr10 mb10">入库类型：</span>
          <span class="tag w200 mr10 mb10">{{voucherTypeNamerk}}</span>
        </div>

        <a
          v-if="!isInSeedetail && !isSeedetail"
          class="link inline_block lh36 fr ml10"
          type="primary"
          @click="exportExcelTemplate"
        >{{ disabledbo == true ? "面料转成衣导入模板" : (isMaterialsTransform == true ? "物料转号导入模板" : "面料转样本画册导入模板") }}</a>
        <!-- >面料转样本画册导入模板</a> -->
      </div>
      <div v-if="!isSeedetail && !isInSeedetail">
        <upload-excel-component
          class="mb20"
          :on-success="importExcelSuccess"
          :before-upload="importExcelBeforeUpload" />
        <div class="edit_wrap">
          <editDoubleTable
            :tableList="editList"
            :tableLeaderFieldsList="leaderFieldsList"
            :tableTailFieldsList="tailFieldsList"
            :tableFieldsList="editFieldsList"
            @inputChange="inputChange"
            @selectChange="selectChange"
            @selectVisibleChange="selectVisibleChange"
            @datePickerChange="datePickerChange"
            @numberInputChange="numberInputChange"
            @deleteChlidHandle="deleteEditItemHandle"
            @addHandle="addEditItemHandle"
          ></editDoubleTable>
          <div class="mt20 fr">
            <el-button @click="cancelEditHandle">取消</el-button>
            <el-button type="primary" v-preventReClick @click="saveEditHandle('save')">保存</el-button>
            <el-button type="primary" v-preventReClick @click="saveEditHandleconfirm('submit')">确认</el-button>
          </div>
        </div>
      </div>
      <!-- 发货单详情 -->
      <div v-else-if="isSeedetail" class="edit_wrap">
        <el-table class="table_list" :data="editListfh" border fit>
          <el-table-column label="行号">
            <template slot-scope="{ row }">{{ row.lineNum }}</template>
          </el-table-column>
          <el-table-column label="生产物料编号">
            <template slot-scope="{ row }">{{ row.productionMaterialCode }}</template>
          </el-table-column>
          <el-table-column label="原材料产品编号">
            <template slot-scope="{ row }">{{ row.sourceProductCode }}</template>
          </el-table-column>
          <el-table-column label="原材料物料编号">
            <template slot-scope="{ row }">{{ row.sourceMaterialCode }}</template>
          </el-table-column>
          <el-table-column v-if="!disabledbo && !isMaterialsTransform" label="页码">
            <template slot-scope="{ row }">{{ row.pageNumber }}</template>
          </el-table-column>
          <el-table-column v-if="!disabledbo && !isMaterialsTransform" label="位置">
            <template slot-scope="{ row }">{{ row.position }}</template>
          </el-table-column>
          <el-table-column label="原材料发货数量">
            <template slot-scope="{ row }">{{ row.deliveryQuantityMaterials }} {{ row.purchaseMeasurementUnit }}</template>
          </el-table-column>
          <el-table-column label="出库仓位">
            <template slot-scope="{ row }">{{ row.inventoryName }}</template>
          </el-table-column>
          <el-table-column label="出库库位">
            <template slot-scope="{ row }">{{ row.subInventoryName }}</template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="{ row }">{{ row.remarks }}</template>
          </el-table-column>
        </el-table>
        <div class="mt20 fr">
          <el-button @click="cancelEditHandle">关闭</el-button>
        </div>
      </div>
      <!-- 余料入库详情 -->
      <div v-else-if="isInSeedetail && voucherTypeNamerk!='生产入库'" class="edit_wrap">
        <el-table class="table_list" :data="editListrk" border fit>
          <el-table-column label="行号">
            <template slot-scope="{ row }">{{ row.lineNumber }}</template>
          </el-table-column>
          <el-table-column label="原材料物料编号">
            <template slot-scope="{ row }">{{ row.materialCode }}</template>
          </el-table-column>
          <el-table-column label="原材料退回数量">
            <template slot-scope="{ row }">{{ row.receiptQuantity }} {{row.purchaseMeasurementUnit}}</template>
          </el-table-column>
          <el-table-column label="入库仓位">
            <template slot-scope="{ row }">{{ row.inventoryName }}</template>
          </el-table-column>
          <el-table-column label="入库库位">
            <template slot-scope="{ row }">{{ row.inSubInventoryName }}</template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="{ row }">{{ row.remarks }}</template>
          </el-table-column>
        </el-table>
        <div class="mt20 fr">
          <el-button @click="cancelEditHandle">关闭</el-button>
        </div>
      </div>
      <!-- 样本画册入库详情 -->
      <div v-else-if="isInSeedetail && voucherTypeNamerk=='生产入库'" class="edit_wrap">
        <el-table class="table_list" :data="editListrkp" border fit>
          <el-table-column label="行号">
            <template slot-scope="{ row }">{{ row.lineNumber }}</template>
          </el-table-column>
          <el-table-column label="物料编号">
            <template slot-scope="{ row }">{{ row.materialCode }}</template>
          </el-table-column>
          <el-table-column label="物料数量">
            <template slot-scope="{ row }">{{ row.receiptQuantity }} {{row.purchaseMeasurementUnit}}</template>
          </el-table-column>
          <el-table-column label="入库仓位">
            <template slot-scope="{ row }">{{ row.inventoryName }}</template>
          </el-table-column>
          <el-table-column label="入库库位">
            <template slot-scope="{ row }">{{ row.inSubInventoryName }}</template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="{ row }">{{ row.remarks }}</template>
          </el-table-column>
        </el-table>
        <div class="mt20 fr">
          <el-button @click="cancelEditHandle">关闭</el-button>
        </div>
      </div>
    </div>

    <!-- 子项详情弹窗 -->
    <div class="popup_part">
      <el-dialog
        :title="popupTitle"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogFormVisible">
        <el-tabs>
          <ul class="childlist">
            <li :class="item.class" v-for="(item, index) in allFieldList" :key="index">{{item.name}}</li>
          </ul>
          <div class="allparentlist">
            <div
              class="list_group"
              v-for="(childItem, index) in checkProduceList.sampleStoriList"
              :key="index">
              <ul
                class="childcontentlist"
                v-for="(simpleItem, simpleIndex) in childItem.sampleList"
                :key="simpleIndex">
                <li class="w6p" :title="simpleItem.sourceProductCode">
                  {{simpleItem.sourceProductCode ?
                  simpleItem.sourceProductCode : ''}}
                </li>
                <li class="w6p" :title="simpleItem.sourceMaterialCode">
                  {{simpleItem.sourceMaterialCode ?
                  simpleItem.sourceMaterialCode : ''}}
                </li>
                <li class="w6p" :title="simpleItem.materialType">
                  {{simpleItem.materialType ? simpleItem.materialType :
                  ''}}
                </li>
                <li class="w6p" :title="simpleItem.sourceQuantity">
                  {{simpleItem.sourceQuantity ?
                  simpleItem.sourceQuantity : ''}}
                </li>
                <li class="w6p" :title="simpleItem.supplierName">
                  {{simpleItem.supplierName ? simpleItem.supplierName :
                  ''}}
                </li>
                <li v-if="simpleIndex === 0" class="w6p" :title="childItem.requiredDate">
                  {{childItem.requiredDate ?
                  childItem.requiredDate : ''}}
                </li>
                <li
                  v-if="simpleIndex === 0"
                  class="w6p"
                  :title="childItem.productionMaterialCode"
                >{{childItem.productionMaterialCode ? childItem.productionMaterialCode : ''}}</li>
                <li
                  v-if="simpleIndex === 0"
                  class="w6p"
                  :title="childItem.productionQuantity"
                >{{childItem.productionQuantity ? childItem.productionQuantity : ''}}</li>
                <li v-if="simpleIndex !== 0" class="w6p"></li>
                <li v-if="simpleIndex !== 0" class="w6p"></li>
                <li v-if="simpleIndex !== 0" class="w6p"></li>
                <li class="w6p" :title="simpleItem.outVirtualInventoryName">
                  {{simpleItem.outVirtualInventoryName ?
                  simpleItem.outVirtualInventoryName : ''}}
                </li>
                <li class="w6p" :title="simpleItem.outSubInventoryName">
                  {{simpleItem.outSubInventoryName ?
                  simpleItem.outSubInventoryName : ''}}
                </li>
                <li class="w6p" :title="simpleItem.inVirtualInventoryName">
                  {{simpleItem.inVirtualInventoryName ?
                  simpleItem.inVirtualInventoryName : ''}}
                </li>
                <li class="w6p" :title="simpleItem.inSubInventoryName">
                  {{simpleItem.inSubInventoryName ?
                  simpleItem.inSubInventoryName : ''}}
                </li>
                <li class="w6p" :title="simpleItem.inventoryInNum">
                  {{simpleItem.inventoryInNum ?
                  simpleItem.inventoryInNum : ''}}
                </li>
                <li
                  class="w6p"
                  :title="simpleItem.confirmNo"
                >{{simpleItem.confirmNo ? simpleItem.confirmNo : ''}}</li>
                <li
                  class="w6p"
                  :title="simpleItem.remark"
                >{{simpleItem.remark ? simpleItem.remark : ''}}</li>
                <li
                  class="w6p"
                  :title="simpleItem.auditOption"
                >{{simpleItem.auditOption ? simpleItem.auditOption : ''}}</li>
              </ul>
            </div>
          </div>
        </el-tabs>
      </el-dialog>
    </div>

    <!-- 删除弹窗 -->
    <el-dialog
      title="提示"
      v-dialogDrag
      :visible.sync="deleteDialogVisible"
      :close-on-click-modal="false"
      width="30%">
      <span>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDeleteHandle">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 关闭弹窗 -->
    <el-dialog
      title="提示"
      v-dialogDrag
      :visible.sync="closeDialogVisible"
      :close-on-click-modal="false"
      width="30%">
      <span>确定关闭？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCloseHandle">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 提交审批弹窗 -->
    <el-dialog
      title="提示"
      v-dialogDrag
      :visible.sync="submitDialogVisible"
      :close-on-click-modal="false"
      width="30%">
      <span>确定提交确认？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSubmitHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { purchasegetInventorylast } from "@/api/module/transfersApi.js";
import { getUUID } from "@/api/publicApi.js";
import {
  toolProductionExecutionList,
  toolProduceDraftList,
  productionEdOrderCode,
  materialClassList,
  deleteFabricProduce,
  deleteFhProduce,
  closeFabricProduces,
  editProduceList,
  editProduceFhList,
  submitProduceList,
  submitProduceEducitionList,
  searchFabricProduce,
  searchFabricProduceFh,
  searchFabricProducerk,
  inventoryTypeList,
  subInventoryTypeList,
  salesSeasonList,
  constDictList,
  getOriginCodeByMCV2,
  getShippedQuantity
} from "@/api/module/toolProduceApi.js";
import { timeFormat, getTimeRange, excelDateFormatToDate, groupBy, sum } from "@/utils/index.js";
import { statusTypeHandle } from "@/utils/public.js";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import Table from "@/layouts/components/common/Table.vue";
import editDoubleTable from "@/layouts/components/common/EditDoubleTableList.vue";
import { regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
export default {
  name: "productionExecution",
  props: {
    disabledbo: Boolean,
    isMaterialsTransform: Boolean
  },
  data() {
    return {
      applicantAdd: "", //新增创建人
      editListrk: [],
      editListrkp: [],
      deliverCoderk: "", //发货单号
      createTimerk: "",
      createUserrk: "", //创建人
      voucherTypeNamerk: "",
      prepareReceiptCoderk: "",
      editListfh: [],
      deliverCodefh: "", //发货单号
      createTimefh: "",
      remarksfh: "",
      purpose: "", //创建人
      receiverAddress: "",
      transportNumber: "",
      tableLoading: true,
      productionOrderType: "", //搜索 生产类型
      waybillNo: "", //运单号
      productionOrderStatus: "", //搜索 生产订单状态
      productionOrderCode: "", //搜索 生产订单编号
      productionMaterialCode: "", //搜索 生产物料编号
      productionType: "",
      // purposeType: '',
      purpose: "",
      receiver: "", //收货人
      receiverContactPhone: "", //收货人联系电话
      receiverAddress: "", //收货人地址
      // salesSeasonType: '',
      salesSeason: "",
      customerCode: "",
      isShowVal: false,
      isSeedetail: false, //编辑头部信息选择显示
      isInSeedetail: false, //入库详情查询
      editfahuodan: false,
      deliverCode: "",
      materialClassList: [],
      materialStatusList: [
        {
          name: "打开",
          index: "1"
        },
        {
          name: "关闭",
          index: "2"
        }
      ],
      salesSeasonList: [],
      expands: [],
      dialogFormVisible: false,
      popupTitle: "",
      deleteId: "",
      closeId: "",
      deliverFhCode: "",
      productOrderCode: "",
      submitParamsData: {},
      deleteDialogVisible: false,
      closeDialogVisible: false,
      submitDialogVisible: false,
      isHide: false,
      isShowEdit: false,
      isShowList: false,
      isSaveDisabled: false,
      isSubmitDisabled: false,
      activeName: "all",
      stStatus: "",
      dateVal: "",
      sourceProductCode: "",
      sourceMaterialCode: "",
      productionMaterialCode: "",
      supplierName: "",
      beginTime: "",
      endTime: "",
      uuid: "",
      uuids: "",
      total: 0,
      pageSize: 10, //每页显示条数
      currentPage: 1, //页数
      produceList: [],
      checkProduceList: [],
      editList: [],
      outInventoryList: [], //出库仓位列表
      outInventoryListck: [],
      outSubInventoryList: [], //出库库位列表
      inInventoryList: [], //入库仓位列表
      inSubInventoryList: [], //入库库位列表
      isPassShipments: false,

      // NEW TBALE组件列表
      multipleSelection: [],
      childMultipleSelection: [],
      selectUuidList: [],
      editPartLoading: false,

      // 省市县三级联动
      province: "", // 省
      city: "", // 市
      county: "", // 县
      // provinceData: null,
      // cityData: null,
      // countyData: null,
      regionOptions: regionDataPlus,
      region: [],

      allFieldList: [
        {
          name: "原材料产品编号",
          class: "w6p"
        },
        {
          name: "原材料物料编号",
          class: "w6p"
        },
        {
          name: "原材料类型",
          class: "w6p"
        },
        {
          name: "原材料消耗数量",
          class: "w6p"
        },
        {
          name: "供应商",
          class: "w6p"
        },
        {
          name: "期望交期",
          class: "w6p"
        },
        {
          name: "生产物料编号",
          class: "w6p"
        },
        {
          name: "生产数量",
          class: "w6p"
        },
        {
          name: "出库仓位",
          class: "w6p"
        },
        {
          name: "出库库位",
          class: "w6p"
        },
        {
          name: "入库仓位",
          class: "w6p"
        },
        {
          name: "入库库位",
          class: "w6p"
        },
        {
          name: "已入库数",
          class: "w6p"
        },
        {
          name: "确认书号",
          class: "w6p"
        },
        {
          name: "备注",
          class: "w6p"
        }
      ],
      parentFieldsList: [
        // Table父列表表头
        {
          name: "创建时间",
          class: "w8p"
        },
        {
          name: "申请人",
          class: "w4p"
        },
        {
          name: "生产订单编号",
          class: "w12p"
        },
        {
          name: "生产类型",
          class: "w5p"
        },
        {
          name: "用途",
          class: "w5p"
        },
        {
          name: "销售季",
          class: "w5p"
        },
        {
          name: "审批",
          class: ""
        }
      ],
      editFieldList: [
        {
          name: "生产物料编号",
          class: "w8p",
          isRules: true
        },
        {
          name: "生产数量",
          class: "w8p",
          isRules: true
        },
        {
          name: "期望交期",
          class: "w8p",
          isRules: true
        },
        {
          name: "原材料产品编号",
          class: "w8p",
          isRules: true
        },
        {
          name: "原材料物料编号",
          class: "w8p",
          isRules: true
        },
        {
          name: "原材料消耗数量",
          class: "w8p",
          isRules: true
        },
        {
          name: "出库仓位",
          class: "w8p",
          isRules: true
        },
        {
          name: "出库库位",
          class: "w8p",
          isRules: true
        },
        {
          name: "入库仓位",
          class: "w8p",
          isRules: true
        },
        {
          name: "入库库位",
          class: "w8p",
          isRules: true
        },
        {
          name: "备注",
          class: "w8p"
        },
        {
          name: "审批意见",
          class: "w4p"
        },
        {
          name: "",
          class: "w3p"
        }
      ],
      purposeTypeList: [
        {
          value: "客用",
          name: "客用"
        },
        {
          value: "自用",
          name: "自用"
        }
      ],
      addData: {
        lineNum: "",
        requiredDate: "",
        productionMaterialCode: "",
        sampleUuid: "",
        productionQuantity: "",
        inInventoryList: [],
        inVirtualInventoryCode: "",
        inVirtualInventoryName: "",
        inSubInventoryCode: "",
        inSubInventoryName: "",
        remark: "",
        sampleList: []
      },
      addItemData: {
        lineNum: "",
        uuid: "",
        sourceProductCode: "",
        sourceMaterialCode: "",
        sourceQuantity: 0.0,
        deliveryQuantityMaterials: 0.0,
        outVirtualInventoryCode: "B0",
        outSubInventory: "",
        outVirtualInventoryName: "零剪仓",
        outSubInventoryCode: "52",
        inSubInventory: "",
        outSubInventoryName: "零剪样本布仓库",
        confirmNumbers: "",
        auditOption: "",
        remark: "",
        inVirtualInventoryNumber: 0
      },
      submitData: {
        submitType: "",
        uuid: "",
        receiver: "", //收货人
        receiverContactPhone: "", //收货人联系电话
        receiverAddress: "", //收货人地址
        moDeliverOrderDetailsItemOut: [],
        purpose: "",
        productionType: "",
        salesSeason: "",
        customerCode: ""
      },
      subNavList: [
        {
          label: "全部已通过生产订单",
          value: "all"
        }
      ],

      // Table组件
      parentData: {
        status: "",
        data: [
          {
            name: "创建时间",
            field: "createTime",
            class: "w8p",
            isShowTit: true
          },
          {
            name: "申请人",
            field: "applicantName",
            class: "w4p",
            isShowTit: true
          },
          {
            name: "生产订单编号",
            field: "productionOrderCode",
            class: "w12p",
            isShowTit: true
          },
          {
            name: "生产类型",
            field: "productionType",
            class: "w5p",
            isShowTit: true
          },
          {
            name: "用途",
            field: "purpose",
            class: "w5p",
            isShowTit: true
          },
          {
            name: "销售季",
            field: "salesSeason",
            class: "w5p",
            isShowTit: true,
            isFilterField: true
          },
          {
            name: "审批",
            field: "",
            class: "",
            isShowTit: false,
            isPopover: true
          },
          {
            name: "",
            field: "",
            class: "fr mr15",
            isShowTit: false,
            isUpDown: true
          },
          {
            name: "",
            field: "",
            class: "fr",
            isShowTit: false,
            isAction: true
          }
        ]
      },
      childData: {
        type: "double",
        name: "sampleStoriList",
        childName: "sampleList",
        data: [
          {
            name: "",
            field: "sourceProductCode",
            class: "w6p"
          },
          {
            name: "",
            field: "sourceMaterialCode",
            class: "w6p"
          },
          {
            name: "",
            field: "materialTypeName",
            class: "w6p"
          },
          {
            name: "",
            field: "sourceQuantity",
            class: "w6p"
          },
          {
            name: "",
            field: "supplierName",
            class: "w6p"
          },
          {
            name: "",
            field: "requiredDate",
            class: "w6p",
            isChildField: true
          },
          {
            name: "",
            field: "productionMaterialCode",
            class: "w6p",
            isChildField: true
          },
          {
            name: "",
            field: "productionQuantity",
            class: "w6p",
            isChildField: true
          },
          {
            name: "",
            field: "outVirtualInventoryName",
            class: "w6p"
          },
          {
            name: "",
            field: "outSubInventoryName",
            class: "w6p"
          },
          {
            name: "",
            field: "inVirtualInventoryName",
            class: "w6p"
          },
          {
            name: "",
            field: "inSubInventoryName",
            class: "w6p"
          },
          {
            name: "",
            field: "inventoryInNum",
            class: "w6p"
          },
          {
            name: "",
            field: "confirmNo",
            class: "w6p"
          },
          {
            name: "",
            field: "remark",
            class: "w6p"
          }
          // {
          //   name: '',
          //   field: 'auditOption',
          //   class: 'w6p'
          // }
        ]
      },

      // 编辑组件数据
      editFieldsList: [],
      moreEditFieldsList: [
        {
          name: "行号",
          field: "lineNum",
          class: "w10p",
          width: "50",
          chlidListName: "sampleList"
        },
        {
          name: "*生产物料编号",
          field: "productionMaterialCode",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 原材料物料编号",
          field: "sourceMaterialCode",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "页码",
          field: "pageNumber",
          class: "w8p",
          width: "150"
        },
        {
          name: "位置",
          field: "position",
          class: "w8p",
          width: "150"
        },
        {
          name: "* 出库仓位",
          // field: 'outVirtualInventoryCode',
          field: "outVirtualInventoryName",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 出库库位",
          field: "outSubInventoryName",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "计划消耗总数量",
          field: "sourceQuantity",
          class: "w8p",
          width: "150"
        },
        {
          name: "原材料已发货数量",
          field: "shippedQuantity",
          class: "w8p",
          width: "150"
        },
        {
          name: "* 原材料可用库存",
          field: "inVirtualInventoryNumber",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 原材料发货数量",
          field: "deliveryQuantityMaterials",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "备注",
          field: "remark",
          class: "w8p",
          width: "150"
        }
      ],
      lessEditFieldsList: [
        {
          name: "行号",
          field: "lineNum",
          class: "w10p",
          width: "50",
          chlidListName: "sampleList"
        },
        {
          name: "*生产物料编号",
          field: "productionMaterialCode",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 原材料物料编号",
          field: "sourceMaterialCode",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 出库仓位",
          // field: 'outVirtualInventoryCode',
          field: "outVirtualInventoryName",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 出库库位",
          field: "outSubInventoryName",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "计划消耗总数量",
          field: "sourceQuantity",
          class: "w8p",
          width: "150"
        },
        {
          name: "原材料已发货数量",
          field: "shippedQuantity",
          class: "w8p",
          width: "150"
        },
        {
          name: "* 原材料可用库存",
          field: "inVirtualInventoryNumber",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "* 原材料发货数量",
          field: "deliveryQuantityMaterials",
          class: "w8p",
          width: "150",
          isRules: true
        },
        {
          name: "备注",
          field: "remark",
          class: "w8p",
          width: "150"
        }
      ],

      leaderFieldsList: [
        // 取的生产材料编号
      ],
      tailFieldsList: [
        {
          name: "操作",
          field: "",
          class: "",
          width: "100",
          isAction: true,
          isChlidAction: true,
          hasAdd: false,
          hasChildDelete: true
        }
      ],
      // 编辑字段呈现方式集合（默认添加模式）
      editModeSet: {},
      textSet: {
        lineNum: true,
        auditOption: true,
        productionMaterialCode: true,
        sourceProductCode: true,
        sourceMaterialCode: true,
        inVirtualInventoryNumber: true, //可用库存
        // inVirtualInventoryNumberName:true,
        pageNumber: true,
        position: true,
        shippedQuantity: true,
        sourceQuantity: true,
        outVirtualInventoryName: {
          selectListName: "outInventoryList",
          value: "index",
          label: "name"
        },
        outSubInventoryName: {
          selectListName: "outSubInventoryList",
          value: "subInventory",
          label: "subInventoryName"
        },
        inVirtualInventoryName: {
          selectListName: "inInventoryList",
          value: "index",
          label: "name"
        },
        inSubInventoryName: {
          selectListName: "inSubInventoryList",
          value: "subInventory",
          label: "subInventoryName"
        }
      },
      inputSet: {
        remark: true
      },
      selectSet: {},
      numberInputSet: {
        // sourceQuantity: true,
        deliveryQuantityMaterials: true
      },
      datePickerSet: {
        requiredDate: true
      },
      unitSet: {}

      // childHeaderList: ['行号', '原材料产品编号', '原材料物料编号', '页码', '位置', '出库仓位', '出库库位', '原材料可用库存', '原材料消耗数量', '备注'],
      // childFieldsList: ['sn', 'sourceProductCode', 'sourceMaterialCode', 'pageNumber', 'position', 'outVirtualInventoryName', 'outSubInventoryName', 'inVirtualInventoryName', 'sourceQuantity', 'remark'],
    };
  },
  components: {
    UploadExcelComponent,
    Table,
    editDoubleTable
  },
  beforeCreate() {},
  created() {},
  mounted() {
    if (this.disabledbo || this.isMaterialsTransform) {
      this.editFieldsList = this.lessEditFieldsList;
    } else {
      this.editFieldsList = this.moreEditFieldsList;
    }
    this.$forceUpdate();
    // this.getFabricProduceList();
    this.getMaterialClassList();
    this.getSalesSeasonList();
    this.getInventoryList();
    this.getSubInventoryList();
    this.addData.sampleList.push(this.addItemData);
    this.fun_date();
    this.checkDefault(); //检查出库库位是否存在默认值
  },
  activated() {},
  filters: {
    statusFilter(status) {
      const statusMap = {
        Draft: "",
        Approving: "warning",
        Approved: "success",
        Rejected: "danger",
        Terminated: "info"
      };
      return statusMap[status];
    }
  },
  watch: {},
  methods: {
    // 省市县三级联动
    regionCascaderChange(val) {
      console.log('省市县三级联动：', val, val[0], val[1], val[2]);
      this.region = val;
      this.province = val[0] && val[0] !== undefined ? CodeToText[val[0]] : '';
      this.city = val[1] && val[1] !== undefined ? CodeToText[val[1]] : '';
      this.county = val[2] && val[2] !== undefined ? CodeToText[val[2]] : '';
      console.log('省市县：', this.province, this.city, this.county);
    },

    childExcelDownload(item) {
      if (this.disabledbo || this.isMaterialsTransform) {
        let headerList = ["行号", "原材料产品编号", "原材料物料编号", "出库仓位", "出库库位", "原材料可用库存", "原材料消耗数量", "备注"];
        let fieldList = [
          "sn",
          "sourceProductCode",
          "sourceMaterialCode",
          "outVirtualInventoryName",
          "outSubInventoryName",
          "inVirtualInventoryName",
          "sourceQuantity",
          "remark"
        ];
      } else {
        let headerList = [
          "行号",
          "原材料产品编号",
          "原材料物料编号",
          "页码",
          "位置",
          "出库仓位",
          "出库库位",
          "原材料可用库存",
          "原材料消耗数量",
          "备注"
        ];
        let fieldList = [
          "sn",
          "sourceProductCode",
          "sourceMaterialCode",
          "pageNumber",
          "position",
          "outVirtualInventoryName",
          "outSubInventoryName",
          "inVirtualInventoryName",
          "sourceQuantity",
          "remark"
        ];
      }
      import("@/vendor/Export2Excel").then(excel => {
        let newList = [];
        const list = item.sampleStoriList;
        list.forEach(x => {
          x.sampleList.forEach(y => {
            newList.push(y);
          });
        });
        const data = this.formatJson(fieldList, newList);
        excel.export_json_to_excel({
          header: headerList,
          data,
          filename: item.productionOrderCode,
          autoWidth: true,
          bookType: "xlsx"
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    exportExcelTemplate() {
      let headerList = [];
      let fieldList = [];
      let fileName = "面料转样本画册导入模板";
      if (this.disabledbo) {
        fileName = "面料转成衣导入模板";
      } else if (this.isMaterialsTransform) {
        fileName = "物料转号导入模板";
      }
      if (this.disabledbo || this.isMaterialsTransform) {
        headerList = ["行号", "生产物料编号", "原材料物料编号", "出库仓位", "出库库位", "计划消耗总数量", "原材料已发货数量", "原材料可用库存", "原材料发货数量", "备注"];
        fieldList = [
          "lineNum",
          "productionMaterialCode",
          "sourceMaterialCode",
          "outVirtualInventoryName",
          "outSubInventoryName",
          "sourceQuantity",
          "shippedQuantity",
          "inVirtualInventoryNumber",
          "deliveryQuantityMaterials",
          "remark"
        ];
      } else {
        headerList = [
          "行号",
          "生产物料编号",
          "原材料物料编号",
          "页码",
          "位置",
          "出库仓位",
          "出库库位",
          "计划消耗总数量",
          "原材料已发货数量",
          "原材料可用库存",
          "原材料发货数量",
          "备注"
        ];
        fieldList = [
          "lineNum",
          "productionMaterialCode",
          "sourceMaterialCode",
          "pageNumber",
          "position",
          "outVirtualInventoryName",
          "outSubInventoryName",
          "sourceQuantity",
          "shippedQuantity",
          "inVirtualInventoryNumber",
          "deliveryQuantityMaterials",
          "remark"
        ];
      }

      import("@/vendor/Export2Excel").then(excel => {
        let newList = [];
        const list = this.editList;
        list.forEach(x => {
          x.sampleList.forEach(y => {
            newList.push(y);
          });
        });
        const data = this.formatJson(fieldList, newList);
        excel.export_json_to_excel({
          header: headerList, //Header Required
          data, //Specific data Required
          filename: fileName, //Optional
          autoWidth: true, //Optional
          bookType: "xlsx" //Optional
        });
      });
    },
    // Upload Excel Edit
    importExcelBeforeUpload(file) {
      let isType = false;
      let fileName = file.name;
      let fileType = fileName.substr(fileName.lastIndexOf(".") + 1);
      const isXLSX = fileType === "xlsx";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({
          message: "请不要上传大于1M的文件！",
          type: "error"
        });
        return false;
      }
      if (!isXLSX) {
        this.$message.error("仅支持上传.xlsx后缀文件！");
      }
      if (isXLSX) {
        // this.editPartLoading = true;
        isType = true;
      }
      return isType;
    },
    async importExcelSuccess({ results, header }) {
      console.log("导入数据：", results, header);
      if (results && results.length) {
        let editListCopy = JSON.parse(JSON.stringify(this.editList));
        if (!this.editfahuodan) {
          // 新增发货
          let list = [];
          for (let i = 0; i < editListCopy.length; i++) {
            let x = editListCopy[i];
            if (x.sampleList && x.sampleList.length) {
              for (let j = 0; j < x.sampleList.length; j++) {
                list.push(x.sampleList[j]);
              }
            }
          }
          
          for (let i = 0; i < results.length; i++) {
            let item = results[i];
            if (list[i]) {
              Object.keys(item).map(key => {
                console.log("导入Excel表数据：", key, item[key]); // key=>属性名  obj[key]=>属性值
                if (key === "原材料发货数量") {
                  // list[i].sourceQuantity = item[key];
                  list[i].deliveryQuantityMaterials = item[key];
                } else if (key === "备注") {
                  list[i].remark = item[key];
                } else if (key === "行号") {
                  // list[i].lineNum = item[key];
                }
              
              });
            }
          }

          this.editList.forEach((x, i) => {
            let arr1 = list.splice(0, this.editList[i].sampleList.length);
            x.sampleList = JSON.parse(JSON.stringify(arr1));
          });

          for (let i = 0; i < this.editList.length; i++) {
            let item = this.editList[i];
             item.sampleList = item.sampleList.filter(cItem => {
                   return Number(cItem.deliveryQuantityMaterials) != 0
             })
         
        console.log(item);
           this.updateEditModeSet('edit', item);
           this.$forceUpdate();
          }
         
        } else {
          for (let i = 0; i < results.length; i++) {
            let item = results[i];
            if (editListCopy[i].sampleList) {
              Object.keys(item).map(key => {
                console.log("导入Excel表数据：", key, item[key]); // key=>属性名  obj[key]=>属性值
                if (key === "原材料发货数量") {
                  // editListCopy[i].sampleList[0].sourceQuantity = item[key];
                  editListCopy[i].sampleList[0].deliveryQuantityMaterials = item[key];
                } else if (key === "备注") {
                  editListCopy[i].sampleList[0].remark = item[key];
                } else if (key === "行号") {
                  // editListCopy[i].sampleList[0].lineNum = item[key];
                }
              });
            }
            
          }
          this.editList = editListCopy;
          for (let i = 0; i < this.editList.length; i++) {
            let item = this.editList[i];
             item.sampleList = item.sampleList.filter(cItem => {
                   return parseInt(cItem.deliveryQuantityMaterials) != 0
             })
         
           console.log(item);
           this.updateEditModeSet('edit', item);
           this.$forceUpdate();
          }
        }
        this.$forceUpdate();
        this.editPartLoading = false;
        setTimeout(() => {
          this.editPartLoading = false;
        }, 10000);
      }
    },
    rowClick(row, event, column) {
      //单击事件
      console.log(row, event, column);
      Array.prototype.remove = function(val) {
        let index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (this.expands.indexOf(row.id) < 0) {
        this.expands.push(row.id);
      } else {
        this.expands.remove(row.id);
      }
    },
    async checkDefault() {
      if ((await this.getCheckoutSubInventory()) != true) {
        //检查出库库位是否存在默认值
        this.addItemData.outSubInventoryCode = "";
        this.addItemData.outSubInventoryName = "";
      }
    },
    //固定展示为两周前的时间范围
    fun_date() {
      console.log(getTimeRange(-90)); //三个月前的日期
      this.dateVal = getTimeRange(-90);
      this.searchHandle();
    },
    selectAllHandle(selection) {
      // 父项全选事件
      console.log("触发全选事件：", selection);
      this.selectUuidList = [];
      this.childMultipleSelection = [];
      if (selection && selection.length) {
        for (let i = 0; i < selection.length; i++) {
          let item = selection[i];
          this.selectUuidList.push(item.uuid);
          for (let j = 0; j < item.sampleStoriList.length; j++) {
            let cItem = item.sampleStoriList[j];
            for (let m = 0; m < cItem.sampleList.length; m++) {
              let tItem = cItem.sampleList[m];
              this.childMultipleSelection.push(tItem);
              this.$nextTick(() => {
                if (this.$refs["childMultipleTable_" + item.id] && this.$refs["childMultipleTable_" + item.id].length) {
                  this.$refs["childMultipleTable_" + item.id][0].toggleRowSelection(tItem, true);
                }
              });
            }
          }
        }
      } else {
        for (let i = 0; i < this.produceList.length; i++) {
          let item = this.produceList[i];
          for (let j = 0; j < item.sampleStoriList.length; j++) {
            let cItem = item.sampleStoriList[j];
            for (let m = 0; m < cItem.sampleList.length; m++) {
              let tItem = cItem.sampleList[m];
              this.$nextTick(() => {
                if (this.$refs["childMultipleTable_" + item.id] && this.$refs["childMultipleTable_" + item.id].length) {
                  this.$refs["childMultipleTable_" + item.id][0].toggleRowSelection(tItem, false);
                }
              });
            }
          }
        }
      }
    },
    expandChange(row, rowList) {
      console.log("树形展开项：", row, rowList);
      if (this.selectUuidList.indexOf(row.uuid) > -1) {
        // 父项被勾选
        // await this.$nextTick();
        for (let i = 0; i < row.sampleStoriList.length; i++) {
          let cItem = row.sampleStoriList[i];
          for (let j = 0; j < cItem.sampleList.length; j++) {
            let tItem = cItem.sampleList[j];
            this.$nextTick(() => {
              if (this.$refs["childMultipleTable_" + row.id] && this.$refs["childMultipleTable_" + row.id].length) {
                this.$refs["childMultipleTable_" + row.id][0].toggleRowSelection(tItem, true);
              }
            });
          }
        }
      }
    },
    selectHandle(selection, row) {
      // console.log('当用户手动勾选数据行的 Checkbox 时触发事件：', selection, row);
      let _this = this;
      let _row = row;
      this.selectUuidList = [];
      this.multipleSelection = selection;
      if (selection && selection.length) {
        // await this.$nextTick();
        for (var i = 0; i < selection.length; i++) {
          let item = selection[i];
          this.selectUuidList.push(item.uuid);
        }
        if (this.selectUuidList.indexOf(_row.uuid) > -1) {
          // 当前项选中状态
          for (let i = 0; i < _row.sampleStoriList.length; i++) {
            let cItem = _row.sampleStoriList[i];
            for (let j = 0; j < cItem.sampleList.length; j++) {
              let tItem = cItem.sampleList[j];
              this.$nextTick(() => {
                if (this.$refs["childMultipleTable_" + _row.id] && this.$refs["childMultipleTable_" + _row.id].length) {
                  this.$refs["childMultipleTable_" + _row.id][0].toggleRowSelection(tItem, true);
                }
              });
              this.childMultipleSelection.push(tItem);
            }
          }
        } else {
          for (let i = 0; i < _row.sampleStoriList.length; i++) {
            let cItem = _row.sampleStoriList[i];
            for (let j = 0; j < cItem.sampleList.length; j++) {
              let tItem = cItem.sampleList[j];
              this.$nextTick(() => {
                if (this.$refs["childMultipleTable_" + _row.id] && this.$refs["childMultipleTable_" + _row.id].length) {
                  this.$refs["childMultipleTable_" + _row.id][0].toggleRowSelection(tItem, false);
                }
              });
              this.childMultipleSelection = this.childMultipleSelection.filter(cmsItem => {
                return cmsItem.uuid !== tItem.uuid;
              });
            }
          }
        }
      } else {
        if (this.childMultipleSelection) {
          this.childMultipleSelection = [];
          this.$refs["childMultipleTable_" + _row.id][0].clearSelection();
        }
      }
    },
    async childSelectHandle(selection, row, parentRow, cItem) {
      // 选择子项
      console.log("选择子项：", selection, row, parentRow);
      await this.$nextTick();
      if (selection.length === cItem.sampleList.length) {
        // 子项选择个数与当前列表子项个数一致触发当前父项数据全选
        this.$nextTick(() => {
          console.log("阿斯蒂芬季还是都丑：", this.$refs.multipleTable);
          this.$refs.multipleTable[0].toggleRowSelection(parentRow, true);
        });
      }
      this.$refs.multipleTable[0].toggleRowSelection(parentRow, false);
    },
    async childSelectAllHandle(selection, parentRow) {
      // 子项全选
      console.log("子项全选操作：", selection, parentRow);
      await this.$nextTick();
      if (selection && selection.length) {
        this.$nextTick(() => {
          this.$refs.multipleTable[0].toggleRowSelection(parentRow, true);
        });
      }
      this.$refs.multipleTable[0].toggleRowSelection(parentRow, false);
    },

    deleteHandle(index, item, type) {
      // 删除项
      this.deleteId = item.id;
      this.deleteDialogVisible = true;
    },
    closeHandle(index, item, type) {
      // 删除项
      this.closeId = item.id;
      this.closeDialogVisible = true;
    },
    confirmDeleteHandle() {
      deleteFhProduce(this.deleteId).then(res => {
        if (res.code === "0") {
          this.deleteDialogVisible = false;
          if (this.stStatus === "Draft") {
            this.getFabricProduceDraftList();
          } else {
            this.getFabricProduceList();
          }
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        }
      });
    },
    confirmCloseHandle() {
      let data = {
        id: this.closeId,
        closeStatus: 2
      };
      closeFabricProduces(this.closeId, data).then(res => {
        if (res.code === "0") {
          this.closeDialogVisible = false;
          if (this.stStatus === "Draft") {
            this.getFabricProduceDraftList();
          } else {
            this.getFabricProduceList();
          }
          this.$message({
            type: "success",
            message: "关闭成功!"
          });
        }
      });
    },
    getCheckoutSubInventory() {
      return subInventoryTypeList("B0").then(res => {
        if (res.code === "0") {
          // console.log(res.data)
          this.outSubInventoryList = res.data;
          if (this.outSubInventoryList.findIndex(target => target.subInventory === "52") == -1) {
            return false;
          } else {
            return true;
          }
        }
      });
    },

    async editHandleIn(index, item) {
      this.isInSeedetail = true;
      this.isShowEdit = true;
      searchFabricProducerk(item.id).then(res => {
        console.log('入库单查询：', res);
        if (res.code === "0") {
          let list = res.data.backReceiptDetailList;
          for (var i = 0; i < list.length; i++) {
            let inInventoryData = this.inInventoryList.find(x => {
              return x.index === list[i].inventory;
            });
            list[i].inventoryName = inInventoryData.name;
            list[i].inSubInventoryName = "";
            this.getSubInventoryTypeList(list[i], i, list[i].destInventory, res.data.voucherType);
          }
          if (res.data.voucherType == "ProductionIn") {
            this.editListrkp = list;
          } else {
            this.editListrk = list;
          }

          this.deliverCoderk = item.voucherCode; //发货单号
          this.createTimerk = res.data.createTime ? timeFormat(res.data.createTime) : "";
          this.createUserrk = res.data.createUser; //创建人
          this.voucherTypeNamerk = res.data.voucherType == "ProductionIn" ? "生产入库" : "余料入库";
          this.prepareReceiptCoderk = res.data.prepareReceiptCode;
        }
      });
    },
    async editHandle(index, item, status) {
      // 编辑
      console.log("编辑项：", item);
      let regionCodeList = [];
      this.isShowEdit = true;
      this.isPassShipments = false;
      this.province = item.province ? item.province : "";
      this.city = item.city ? item.city : "";
      this.county = item.county ? item.county : "";
      item.province ? regionCodeList.push(TextToCode[item.province].code) : regionCodeList.push("");
      item.city ? regionCodeList.push(TextToCode[item.province][item.city].code) : regionCodeList.push("");
      item.county ? regionCodeList.push(TextToCode[item.province][item.city][item.county].code) : regionCodeList.push("");
      this.region = regionCodeList;
      this.productionEdOrderCode = item.productionOrderCode ? item.productionOrderCode : item.productCode;
      if (status == "seedetail") {
        this.isSeedetail = true;
        this.isShowEdit = true;
        searchFabricProduceFh(item.id).then(res => {
          console.log("编辑请求数据：", res);
          if (res.code === "0") {
            this.editListfh = res.data.moDeliverOrderDetailsItemOut;
            this.deliverCodefh = res.data.deliverCode; //发货单号
            this.createTimefh = res.data.createTime ? timeFormat(res.data.createTime) : "";
            this.purpose = res.data.applicant; //创建人
            this.receiverAddress = res.data.receiverAddress;
            this.receiver = res.data.receiver;
            this.receiverContactPhone = res.data.receiverContactPhone;
            this.transportNumber = res.data.transportNumber;
            this.remarksfh = res.data.remarks;
          }
        });
      } else {
        this.isShowEdit = true;
        this.getFabricProduceHandle(item, status);
      }
    },

    async getFabricProduceHandle(item, status) {
      let ifdefault = await this.getCheckoutSubInventory();
      await searchFabricProduce(item.id, status).then(async res => {
        console.log("当前操作项：", res);
        if (res.code === "0") {
          if (status == "fahuodan") {
            let list = res.data.moDeliverOrderDetailsItemOut;
            this.editfahuodan = true;
            this.deliverCode = res.data.deliverCode;
            this.editList = [];
            for (let i = 0; i < list.length; i++) {
              let params = {
                materialCode: list[i].sourceMaterialCode,
                inventory: list[i].inventory,
                subInventory: list[i].subInventory
              };
              let sampleList = [];
              let sampleData = {};
              list[i].productionMaterialCode = list[i].productionMaterialCode;
              list[i].sourceProductCode = list[i].sourceProductCode;
              list[i].sourceMaterialCode = list[i].sourceMaterialCode;
              list[i].outVirtualInventoryName = list[i].inventoryName;
              list[i].outSubInventoryName = list[i].subInventoryName;
              list[i].remark = list[i].remarks;
              // list[i].sourceQuantity = list[i].deliveryQuantityMaterials;
              list[i].inVirtualInventoryNumber = "";
              // list[i].inVirtualInventoryNumberName=''
              // this.getAvailableInventoryHandle(list[i], i,params);

              sampleData = JSON.parse(JSON.stringify(list[i]));
              sampleList.push(sampleData);
              // sampleList.push(list[i])
              list[i].sampleList = sampleList;

              for (let j = 0; j < list[i].sampleList.length; j++) {
                let cItem = list[i].sampleList[j];
                // 原材料可用库存
                this.getInVirtualInventoryNumber(params, list[i], i, cItem, j);
                // 已确认发货数量
                this.getShippedQuantityHandle(res.data, list[i], i, cItem, j, status);
                this.updateEditModeSet("edit", cItem);
              }
            }
            this.editList = JSON.parse(JSON.stringify(list));
            this.receiver = res.data.receiver; //收货人
            this.receiverContactPhone = res.data.receiverContactPhone; //收货人联系电话
            this.receiverAddress = res.data.receiverAddress; //收货人地址
            this.uuids = res.data.uuid;
            this.$forceUpdate();
            console.log('编辑发货单数据列表：', this.editList);
          } else {
            this.receiverAddress = '';
            this.receiver = '';
            this.receiverContactPhone = '';
            this.applicantAdd = res.data.applicant;
            let list = res.data.sampleStoriList;
            list = await Promise.all(list.map(async (ele, i) => {
              ele.inInventoryList = this.inInventoryList;
              this.updateEditModeSet('edit', ele);
              ele.sampleList = await Promise.all(ele.sampleList.map(async (cele, j) => {
                let params = {
                  materialCode: cele.sourceMaterialCode,
                  inventory: cele.outVirtualInventoryCode,
                  subInventory: cele.outSubInventoryCode
                };
                cele.outInventoryList = this.outInventoryList;
                cele.lineNum = cele.sn;
                if (ifdefault != true) {
                  cele.outSubInventoryCode = "";
                  cele.outSubInventoryName = "";
                }
                // cele.inVirtualInventoryNumber = "";  // 原材料可用库存
                // this.getAvailableInventoryHandle(cele, j,params,i);

                // 原材料可用库存
                cele.inVirtualInventoryNumber = await this.inVirtualInventoryHandle(params);
                // this.getInVirtualInventoryNumber(params, list[i], i, cele, j);
                // 已确认发货数量
                this.getShippedQuantityHandle(res.data, ele, i, cele, j, status);

                this.updateEditModeSet("edit", cele);
                return cele;
              }));
              return ele;
            }));



            
            // for (let i = 0; i < list.length; i++) {
            //   list[i].inInventoryList = this.inInventoryList;
            //   this.updateEditModeSet("edit", list[i]);
            //   for (var j = 0; j < list[i].sampleList.length; j++) {
            //     let data = list[i].sampleList[j];
            //     let params = {
            //       materialCode: data.sourceMaterialCode,
            //       inventory: data.outVirtualInventoryCode,
            //       subInventory: data.outSubInventoryCode
            //     };
            //     data.outInventoryList = this.outInventoryList;
            //     data.lineNum = data.sn;
            //     if (ifdefault != true) {
            //       data.outSubInventoryCode = "";
            //       data.outSubInventoryName = "";
            //     }
            //     data.inVirtualInventoryNumber = "";  // 原材料可用库存
            //     // this.getAvailableInventoryHandle(data, j,params,i);

            //     // 原材料可用库存
            //     console.log('模温机覅哦', this.inVirtualInventoryHandle(params));
            //     data.inVirtualInventoryNumber = this.inVirtualInventoryHandle(params);
            //     // this.getInVirtualInventoryNumber(params, list[i], i, data, j);
            //     // 已确认发货数量
            //     this.getShippedQuantityHandle(res.data, list[i], i, data, j, status);

            //     this.updateEditModeSet("edit", data);
            //   }
            // }
            this.editList = JSON.parse(JSON.stringify(list));
            this.productionType = res.data.productionType;
            this.purpose = res.data.purpose;
            this.salesSeason = res.data.salesSeason;
            this.customerCode = res.data.customerCode;
            this.uuids = '';
            this.$forceUpdate();
            console.log('创建发货单数据列表：', this.editList);
          }
          
        }
      });
    },

    async getShippedQuantityHandle(params, item, index, cItem, cIndex, type) {
      // 已确认发货数量
      await getShippedQuantity(params.productionOrderCode).then(res => {
        // console.log("已确认发货数量：", res);
        if (res.code === "0" && res.data.length) {
          for (let x = 0; x < res.data.length; x++) {
            let sqItem = res.data[x];
            if (item.productionSn == sqItem.productionSn && cItem.sn == sqItem.sn) {
              cItem.shippedQuantity = sqItem.sum;
              if (type !== 'fahuodan') {
                let num =  Number(cItem.sourceQuantity) - Number(cItem.shippedQuantity) ? Number(cItem.sourceQuantity) - Number(cItem.shippedQuantity) : 0;
                if (num > Number(cItem.inVirtualInventoryNumber)) {
                  cItem.deliveryQuantityMaterials = Number(cItem.inVirtualInventoryNumber);
                } else {
                  cItem.deliveryQuantityMaterials = num;
                }
                // this.$set(this.editList[index].sampleList[cIndex], "", cItem);
                this.$set(this.editList[index].sampleList[cIndex], "deliveryQuantityMaterials", cItem.deliveryQuantityMaterials);
              }
              this.$set(this.editList[index].sampleList[cIndex], "shippedQuantity", cItem.shippedQuantity);
              this.$forceUpdate();
            }
          }
        }
      });
    },
    // async getShippedQuantityCloneHandle(data) {
    //   // 已确认发货数量
    //   console.log("已确认发货数量参数：", data, this.editList);
    //   let editListCopy = JSON.parse(JSON.stringify(this.editList));
    //   // let editListCopy = JSON.parse(JSON.stringify(list));
    //   await getShippedQuantity(data.productionOrderCode).then(res => {
    //     console.log("已确认发货数量：", res);
    //     if (res.code === "0" && res.data.length) {
    //       for (let i = 0; i < editListCopy.length; i++) {
    //         let item = editListCopy[i];
    //         for (let j = 0; j < item.sampleList.length; j++) {
    //           let cItem = item.sampleList[j];
    //           for (let x = 0; x < res.data.length; x++) {
    //             let sqItem = res.data[x];
    //             if (item.productionSn == sqItem.productionSn && cItem.sn == sqItem.sn) {
    //               cItem.shippedQuantity = sqItem.sum;
    //               // let num1 = Number(cItem.inVirtualInventoryNumber) - Number(cItem.shippedQuantity) ? Number(cItem.inVirtualInventoryNumber) - Number(cItem.shippedQuantity) : 0;
    //               let num =  Number(cItem.sourceQuantity) - Number(cItem.shippedQuantity) ? Number(cItem.sourceQuantity) - Number(cItem.shippedQuantity) : 0;
    //               if (num > Number(cItem.inVirtualInventoryNumber)) {
    //                 cItem.deliveryQuantityMaterials = Number(cItem.inVirtualInventoryNumber);
    //               } else {
    //                 cItem.deliveryQuantityMaterials = num;
    //               }

    //               this.$forceUpdate();
    //             }
    //           }
    //         }
    //       }
    //       this.editList = editListCopy;
    //       this.$forceUpdate();
    //     }
    //   });
    // },

    async inVirtualInventoryHandle(params) {
      let res = await purchasegetInventorylast(params);
      if (res.code !== '0') return 0;
      return Number(res.data) < 0 ? 0 : Number(res.data);
    },
    async getInVirtualInventoryNumber(params, item, index, cItem, cIndex) {
      // 原材料可用库存
      await purchasegetInventorylast(params).then(res => {
        console.log("原材料可用库存：", res.data);
        if (res.code === "0") {
          // cItem.inVirtualInventoryNumber = Number(res.data) < 0 ? '0' : '' + res.data;
          let inVirtualInventoryNumber = Number(res.data) < 0 ? "0" : "" + res.data;
          this.$set(this.editList[index].sampleList[cIndex], "inVirtualInventoryNumber", inVirtualInventoryNumber);
          this.$forceUpdate();
        }
      });
    },

    async getSubInventoryTypeList(item, index, data, type) {
      // 库位
      await subInventoryTypeList(data).then(res => {
        if (res.code === "0") {
          let inSelectedName = res.data.find(n => {
            return item.destSubInventory === n.subInventory;
          });
          item.inSubInventoryName = inSelectedName ? inSelectedName.subInventoryName : '';
          if (type == "ProductionIn") {
            this.editListrkp[index].inSubInventoryName = item.inSubInventoryName;
          } else {
            this.editListrk[index].inSubInventoryName = item.inSubInventoryName;
          }
        }
      });
    },
    async getAvailableInventoryHandle(item, index, data, fIndex) {
      await purchasegetInventorylast(data).then(res => {
        if (res.code === "0") {
          console.log(res.data);
          item.inVirtualInventoryNumber = Number(res.data) < 0 ? "0" : "" + res.data;

          if (fIndex >= 0) {
            this.editList[fIndex].sampleList[index].inVirtualInventoryNumber = item.inVirtualInventoryNumber;
            // this.editList[fIndex].sampleList[index].inVirtualInventoryNumberName=item.inVirtualInventoryNumber+""+item.purchaseMeasurementUnit
          } else {
            this.editList[index].sampleList.inVirtualInventoryNumber = item.inVirtualInventoryNumber;
            // this.editList[index].sampleList.inVirtualInventoryNumberName=item.inVirtualInventoryNumber+""+item.purchaseMeasurementUnit
          }
          // this.$set( this.editList[fIndex].sampleList, index, item);
          console.log("console.log( this.editList)" + this.editList);
          console.log(this.editList);
          this.$forceUpdate();
        }
      });
    },

    async submitHandle(index, item) {
      // 提交审批
      console.log('列表提交审批：', item);
      this.isPassShipments = false;
      this.province = item.province ? item.province : "";
      this.city = item.city ? item.city : "";
      this.productionEdOrderCode = item.productionOrderCode ? item.productionOrderCode : item.productCode;
      // await this.editHandle(index, item, 'fahuodan');
      this.getFabricProduceHandle(item, 'fahuodan');
      // this.editList = JSON.parse(JSON.stringify(item));
      this.deliverFhCode = item.id;
      this.submitDialogVisible = true;
    },
    confirmSubmitHandle() {
      this.saveEditHandle('submit');

      // if (this.verifyHandle()) {
      //   editProduceFhList(this.deliverFhCode).then(res => {
      //     // console.log('提交数据：', res);
      //     if (res.code === "0") {
      //       this.submitDialogVisible = false;
      //       // this.stStatus = 'Approving';
      //       // this.activeName = 'Approving';
      //       this.getFabricProduceList();
      //       this.$message({
      //         type: "success",
      //         message: "提交成功!"
      //       });
      //     }
      //   });
      // }
    },
    allCheckHandle(index, item) {
      this.dialogFormVisible = true;
      this.popupTitle = "销售工具生产";
      searchFabricProduce(item.id).then(res => {
        // console.log('当前操作项：', res);
        if (res.code === "0") {
          this.checkProduceList = res.data;
        }
      });
    },
    dateValChange() {},
    searchHandle() {
      // 搜索
      this.produceList = [];
      this.currentPage = 1;
      if (this.dateVal) {
        this.beginTime = this.dateVal[0];
        this.endTime = this.dateVal[1];
      } else {
        this.beginTime = "";
        this.endTime = "";
      }
      if (this.stStatus === "Draft") {
        this.getFabricProduceDraftList();
      } else {
        this.getFabricProduceList();
      }
    },
    async addHandle() {
      // 列表页添加
      console.log("当前新增订单状态：", this.stStatus);
      this.isShowEdit = true;
      this.uuid = "";
      this.productionType = "";
      this.purpose = "";
      this.salesSeason = "";
      this.customerCode = "";
      this.stStatus = "Draft";
      let list = [];
      let addDataCopy = JSON.parse(JSON.stringify(this.addData));
      addDataCopy.inInventoryList = this.inInventoryList;
      let addItemDataCopy = JSON.parse(JSON.stringify(this.addItemData));
      await getUUID().then(res => {
        if (res.code === "0") {
          // addDataCopy.uuid = res.data;
          addDataCopy.sampleUuid = res.data;
        }
      });
      this.updateEditModeSet("add", addDataCopy);
      addDataCopy.sampleList.push(addItemDataCopy);
      console.log(addDataCopy.sampleList);
      addDataCopy.sampleList.forEach(item => {
        item.outInventoryList = this.outInventoryList;
        console.log(this.outSubInventoryList);
        this.updateEditModeSet("add", item);
        getUUID().then(res => {
          if (res.code === "0") {
            item.uuid = res.data;
          }
        });
      });
      list.push(addDataCopy);
      this.editList = list;
    },
    switchTabHandle(tab) {
      // tab切换
      console.log("tab切换：", tab);
      // this.tableLoading = true;
      this.currentPage = 1;
      // NEW TABLE
      this.multipleSelection = [];
      this.childMultipleSelection = [];
      this.selectUuidList = []; // 勾选项清空
      this.$refs.multipleTable[0].clearSelection();
      this.produceList = [];
      switch (tab.name) {
        case "all":
          // 全部
          this.stStatus = "";
          this.getFabricProduceList();
          break;
        case "Draft":
          // 草稿
          this.stStatus = "Draft";
          this.getFabricProduceDraftList();
          break;
        case "Approving":
          // 审批中
          this.stStatus = "Approving";
          this.getFabricProduceList();
          break;
        case "Approved":
          // 已通过
          this.stStatus = "Approved";
          this.getFabricProduceList();
          break;
        case "Rejected":
          // 已驳回
          this.stStatus = "Rejected";
          this.getFabricProduceList();
          break;
        case "Terminated":
          // 已终止
          // this.activeName = 'Terminated';
          this.stStatus = "Terminated";
          this.getFabricProduceList();
          break;
        default:
          // this.activeName = 'all';
          this.stStatus = "";
          this.getFabricProduceList();
          break;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.stStatus === "Draft") {
        this.getFabricProduceDraftList();
      } else {
        this.getFabricProduceList();
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.stStatus === "Draft") {
        this.getFabricProduceDraftList();
      } else {
        this.getFabricProduceList();
      }
    },
    showChildList(index, item) {
      item.isShowList = !item.isShowList;
      this.$forceUpdate();
    },
    async addEditHandle() {
      // 原材料添加
      // console.log('大号添加按钮：', this.editList);
      if (!this.productionType) {
        this.$message({
          type: "error",
          message: "请选择生产类型！"
        });
        return false;
      }
      if (!this.purpose) {
        this.$message({
          type: "error",
          message: "请选择用途！"
        });
        return false;
      }
      if (this.purpose === "自用" && !this.salesSeason) {
        this.$message({
          type: "error",
          message: "请选择销售季！"
        });
        return false;
      }
      if (this.purpose === "客用" && !this.customerCode) {
        this.$message({
          type: "error",
          message: "请输入销售订单编号！"
        });
        return false;
      }
      this.isShowVal = true;
      let addDataCopy = JSON.parse(JSON.stringify(this.addData));
      let addItemDataCopy = JSON.parse(JSON.stringify(this.addItemData));
      await getUUID().then(res => {
        if (res.code === "0") {
          // addDataCopy.uuid = res.data;
          addDataCopy.sampleUuid = res.data;
        }
      });
      addDataCopy.inInventoryList = this.inInventoryList;
      this.updateEditModeSet("add", addDataCopy);
      addDataCopy.sampleList.push(addItemDataCopy);
      addDataCopy.sampleList.forEach(item => {
        item.outInventoryList = this.outInventoryList;
        // item.inInventoryList = this.inInventoryList;
        this.updateEditModeSet("add", item);
        getUUID().then(res => {
          if (res.code === "0") {
            item.uuid = res.data;
          }
        });
      });
      this.editList.push(addDataCopy);

      console.log("新增生产数据：", this.editList);
    },
    async addEditItemHandle(index, data) {
      // 添加样本子数据
      // console.log('添加样本子数据');
      let editListCopy = JSON.parse(JSON.stringify(this.editList));
      let addItemDataCopy = JSON.parse(JSON.stringify(this.addItemData));
      addItemDataCopy.outInventoryList = this.outInventoryList;
      addItemDataCopy.inInventoryList = this.inInventoryList;
      this.updateEditModeSet("add", editListCopy[index]);
      this.updateEditModeSet("add", addItemDataCopy);
      await getUUID().then(res => {
        if (res.code === "0") {
          addItemDataCopy.uuid = res.data;
        }
      });
      editListCopy[index].sampleList.push(addItemDataCopy);
      this.editList = editListCopy;
    },
    deleteEditItemHandle(pi, ci, data) {
      if (this.editfahuodan) {
        for (var j = 0; j < this.editList[pi].sampleList.length; j++) {
          let data = this.editList[pi].sampleList[j];
          if (this.editList[pi].sampleList[j].sampleList) {
            this.editList[pi].sampleList[j].sampleList = [];
          }
        }
        // let editListCopy = JSON.parse(JSON.stringify(this.editList));
        this.editList.splice(pi, 1);
        // this.editList = editListCopy;
        console.log(this.editList);
      } else {
        // console.log('删除子项：', pi, ci, data);
        if (ci === 0 && this.editList[pi].sampleList.length <= 1) {
          let editListCopy = JSON.parse(JSON.stringify(this.editList));
          editListCopy.splice(pi, 1);
          this.editList = editListCopy;
        } else {
          let editListCopy = JSON.parse(JSON.stringify(this.editList));
          editListCopy[pi].sampleList.splice(ci, 1);
          this.editList = editListCopy;
        }
      }
    },
    cancelEditHandle() {
      this.isShowEdit = false;
      this.isShowVal = false;
      this.isSeedetail = false;
      this.isInSeedetail = false;
      this.editfahuodan = false;
      this.productionType = "";
      this.purpose = "";
      this.salesSeason = "";
      this.customerCode = "";
    },
    saveEditHandleconfirm(type) {
      this.$confirm("是否提交确认?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.saveEditHandle(type);
        })
        .catch(function() {});
    },

    verifyHandle() {
      for (var i = 0; i < this.editList.length; i++) {
        for (var j = 0; j < this.editList[i].sampleList.length; j++) {
          let data = this.editList[i].sampleList[j];
          if (!data.deliveryQuantityMaterials) {
            this.$message({
              type: "error",
              message: "请输入原材料发货数量！"
            });
            return false;
          }
          if (data.deliveryQuantityMaterials.toString().indexOf(".") !== -1) {
            let arr = data.deliveryQuantityMaterials.toString().split(".");
            if (arr[0].length > 8) {
              this.$message({
                type: "error",
                message: "原材料消耗数量仅支持整数位8位，请重新输入！"
              });
              return false;
            }
            if (arr[1].length > 2) {
              this.$message({
                type: "error",
                message: "原材料消耗数量仅支持小数位2位，请重新输入！"
              });
              return false;
            }
          } else if (data.deliveryQuantityMaterials.toString().length > 8) {
            this.$message({
              type: "error",
              message: "原材料消耗数量仅支持整数位8位，请重新输入！"
            });
            return false;
          }
          if (data.deliveryQuantityMaterials > data.inVirtualInventoryNumber) {
            this.$message({
              type: "error",
              message: "原材料发货数量不能大于原材料可用库存！"
            });
            return false;
          }
        }
      }
      return true;
    },

    mergeQueryHandle() {
      let editListCopy = JSON.parse(JSON.stringify(this.editList));
      let mcTemp = {};
      for (let i = 0; i < editListCopy.length; i++) {
        let item = editListCopy[i];
        for (let j = 0; j < item.sampleList.length; j++) {
          let cItem = item.sampleList[j];
          let mcKey = cItem.productionMaterialCode + cItem.sourceMaterialCode;
          if (mcTemp[mcKey]) {
            mcTemp[mcKey].productionMaterialCode = mcTemp[mcKey].productionMaterialCode;
            mcTemp[mcKey].sourceMaterialCode = mcTemp[mcKey].sourceMaterialCode;
            mcTemp[mcKey].sourceQuantity = mcTemp[mcKey].sourceQuantity;
            mcTemp[mcKey].shippedQuantity = mcTemp[mcKey].shippedQuantity;
            mcTemp[mcKey].inVirtualInventoryNumber = mcTemp[mcKey].inVirtualInventoryNumber;
            mcTemp[mcKey].deliveryQuantityMaterials = mcTemp[mcKey].deliveryQuantityMaterials;
            mcTemp[mcKey].deliveryQuantityMaterialsTotal = Number(mcTemp[mcKey].deliveryQuantityMaterialsTotal) + Number(cItem.deliveryQuantityMaterials);
          } else {
            mcTemp[mcKey] = {};
            mcTemp[mcKey].productionMaterialCode = cItem.productionMaterialCode;
            mcTemp[mcKey].sourceMaterialCode = cItem.sourceMaterialCode;
            mcTemp[mcKey].sourceQuantity = cItem.sourceQuantity;
            mcTemp[mcKey].shippedQuantity = cItem.shippedQuantity;
            mcTemp[mcKey].inVirtualInventoryNumber = cItem.inVirtualInventoryNumber;
            mcTemp[mcKey].deliveryQuantityMaterials = cItem.deliveryQuantityMaterials;
            mcTemp[mcKey].deliveryQuantityMaterialsTotal = cItem.deliveryQuantityMaterials;
          }
          cItem.sourceMaterialCodeTemp = mcTemp;
          this.$forceUpdate();
        }
      }
      this.editList = editListCopy;
      this.$forceUpdate();
    },

    async saveEditHandle(type) {
      this.submitData = {};
      // 保存编辑数据
      console.log("编辑保存列表：", this.editList);
      let time = null;
      if (!this.editList.length) {
        this.$message({
          type: "error",
          message: "请添加数据！"
        });
        return false;
      }
      await this.mergeQueryHandle();
      console.log("合并后编辑保存列表：", this.editList);
      // 校验
      let datdetail = [];
      if (type == "submit") { // 确认校验
        // 0407 物料归类校验库存
        let result = this.editList.reduce((r, line) => {
          r = [...r, ...line.sampleList];
          return r;
        }, []);
        const materials = groupBy('sourceMaterialCode', result, (sourceMaterialCode, lines) => {
          return {
            deliveryQuantityMaterials: sum('deliveryQuantityMaterials', lines),
            inVirtualInventoryNumber: sum('inVirtualInventoryNumber', lines),
            sourceMaterialCode: lines[0].sourceMaterialCode
          };
        });
        if (Object.values(materials).some(element => Number(element.deliveryQuantityMaterials) > Number(element.inVirtualInventoryNumber))) {
          let material = Object.values(materials).find(element => Number(element.deliveryQuantityMaterials) > Number(element.inVirtualInventoryNumber));
          this.$message({
            type: 'error',
            message: `${material.sourceMaterialCode}原材料发货数量${material.deliveryQuantityMaterials}不能大于原材料可用库存${material.inVirtualInventoryNumber}！`
          });
          return false;
        }
      }
      for (var i = 0; i < this.editList.length; i++) {
        for (var j = 0; j < this.editList[i].sampleList.length; j++) {
          let data = this.editList[i].sampleList[j];
          let dqTotal = Number(data.sourceMaterialCodeTemp[data.productionMaterialCode + data.sourceMaterialCode].deliveryQuantityMaterialsTotal);
          if (!this.editfahuodan) {
            data.materialId = data.sourceMaterialId;
            if (data.id) {
              delete data.id;
            }
            await getUUID().then(res => {
              console.log(res);
              if (res.code === "0") {
                data.uuid = res.data;
              }
            });
            data.inventory = data.outVirtualInventoryCode; //出库仓位
            data.subInventory = data.outSubInventoryCode; //出库库位
            data.inventoryName = data.outVirtualInventoryName; //出库仓位
            data.subInventoryName = data.outSubInventoryName; //出库库位
          }
          data.inInventory = data.inVirtualInventoryCode; //出库仓位
          data.inSubInventory = data.inSubInventoryCode; //出库库位
          data.inInventoryNmae = data.inVirtualInventoryName;
          data.inSubInventoryName = data.inSubInventoryName;
          data.remarks = data.remark;
          if (type == "submit") { //点击确认时校验
              if (!data.deliveryQuantityMaterials) {
                this.$message({
                  type: "error",
                  message: "请输入原材料发货数量！"
                });
                return false;
              }
              if (data.deliveryQuantityMaterials.toString().indexOf(".") !== -1) {
                let arr = data.deliveryQuantityMaterials.toString().split(".");
                if (arr[0].length > 8) {
                  this.$message({
                    message: "原材料消耗数量仅支持整数位8位，请重新输入！",
                    type: "error"
                  });
                  return false;
                }
                if (arr[1].length > 2) {
                  this.$message({
                    message: "原材料消耗数量仅支持小数位2位，请重新输入！",
                    type: "error"
                  });
                  return false;
                }
              } else if (data.deliveryQuantityMaterials.toString().length > 8) {
                this.$message({
                  message: "原材料消耗数量仅支持整数位8位，请重新输入！",
                  type: "error"
                });
                return false;
              }
              // // data.deliveryQuantityMaterials = data.deliveryQuantityMaterials;
              // // if (data.deliveryQuantityMaterials > data.inVirtualInventoryNumber) {
              // if (dqTotal > Number(data.inVirtualInventoryNumber)) {
              //   this.$message({
              //     type: "error",
              //     message: "原材料发货数量不能大于原材料可用库存！"
              //   });
              //   return false;
              // }
              // data.deliveryQuantityMaterials = data.deliveryQuantityMaterials;
              // if (parseFloat(dqTotal.toFixed(2)) > parseFloat(data.inVirtualInventoryNumber ? Number(data.inVirtualInventoryNumber).toFixed(2) : 0)) {
              //   this.$message({
              //     type: "error",
              //     message: `${data.sourceMaterialCode}原材料发货数量${dqTotal}不能大于原材料可用库存${data.inVirtualInventoryNumber}！`
              //     // message: "原材料发货数量不能大于原材料可用库存！"
              //   });
              //   return false;
              // }
          }

          // if (!this.isPassShipments && Number(data.deliveryQuantityMaterials) + Number(data.shippedQuantity) > Number(data.sourceQuantity)) {
          if (!this.isPassShipments && dqTotal + Number(data.shippedQuantity?data.shippedQuantity:0) > Number(data.sourceQuantity)) {
            this.$confirm("请注意原材料发货数量大于计划消耗总数量，确认发货？", "警告", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                // return true;
                this.isPassShipments = true;
                this.saveEditHandle(type);
              })
              .catch(() => {
                return false;
              });
            return false;
          }
          datdetail.push(data);
        }
      }
      this.submitData.productionOrderType = "saleTool";
      // 发货子项
      for (var i = 0; i < datdetail.length; i++) {
        if (datdetail[i].sampleList) {
          datdetail[i].sampleList = [];
        }
      }
      this.submitData.province = this.province;
      this.submitData.city = this.city;
      this.submitData.moDeliverOrderDetailsItemOut = datdetail; //this.editList[0].sampleList;
      this.submitData.receiver = this.receiver; //收货人
      this.submitData.receiverContactPhone = this.receiverContactPhone; //收货人联系电话
      this.submitData.receiverAddress = this.receiverAddress; //收货人地址
      this.submitData.productionOrderCode = this.productionEdOrderCode;
      this.submitData.deliverCode = this.deliverCode;
      // if (!this.editfahuodan) {
      //   this.submitData.applicant = this.applicantAdd;
      // }
      if (!this.uuids) {
        await getUUID().then(res => {
          console.log(res);
          if (res.code === "0") {
            this.submitData.uuid = res.data;
          }
        });
      } else {
        this.submitData.uuid = this.uuids;
      }
      console.log('---type----',type);
      if (type == "submit") {
        this.submitData.moDeliverOrderStatus = 2;
      } else {
        this.submitData.moDeliverOrderStatus = "";
      }
      this.submitData.applicant = this.$store.state.userName;
      this.submitData.applicantName = this.$store.state.realName;
      this.submitData.applicantDepartment = this.$store.state.currentDepartment.id;
      this.submitData.applicantDepartmentName = this.$store.state.currentDepartment.name;
      this.isSaveDisabled = true;
      submitProduceEducitionList(this.submitData).then(res => {
        console.log("保存数据：", res);
        if (res.code === "0") {
          this.isShowEdit = false;
          this.editfahuodan = false;
          this.getFabricProduceList();
          this.$message({
            type: "success",
            message: "保存成功！"
          });
        }
        if (this.submitDialogVisible == true) {
          this.submitDialogVisible = false;
        }
        this.isSaveDisabled = false;
      });
    },
    //全部已通过生产订单列表
    getFabricProduceList() {
      // 销售工具生产列表
      this.tableLoading = true;
      let type = "saleTool";
      if (this.disabledbo) {
        type = "finishedProduct";
      } else if (this.isMaterialsTransform) {
        type = "cover";
      }
      let data = {
        closeStatus: this.productionOrderStatus, //生产订单状态
        productionType: this.productionOrderType, //生产订单类型
        productionOrderCode: this.productionOrderCode, //生产订单编号
        // productionMaterialCode: this.productionMaterialCode,
        beginTime: this.beginTime,
        endTime: this.endTime,
        transportNumber: this.waybillNo, //运单号
        productionOrderType: type
      };
      toolProductionExecutionList(this.currentPage, this.pageSize, data).then(res => {
        console.log("生产执行列表：", res);
        if (res.code === "0") {
          this.produceList = res.data.records;
          this.total = res.data.total;
          let list = this.produceList;
          for (var i = 0; i < list.length; i++) {
            list[i].createTime = list[i].createTime ? timeFormat(list[i].createTime) : "";
            list[i].status = list[i].stStatus;
            list[i].stStatusName = statusTypeHandle(list[i].stStatus) == "已通过" ? "已确认" : statusTypeHandle(list[i].stStatus);

            for (var j = 0; j < list[i].moDeliverOrderDetailListItem.length; j++) {
              list[i].moDeliverOrderDetailListItem[j].createTime = list[i].moDeliverOrderDetailListItem[j].createTime
                ? timeFormat(list[i].moDeliverOrderDetailListItem[j].createTime)
                : "";
              list[i].moDeliverOrderDetailListItem[j].moDeliverOrderStatusName =
                list[i].moDeliverOrderDetailListItem[j].moDeliverOrderStatus == "8" ? "草稿" : "已确认";
              list[i].moDeliverOrderDetailListItem[j].childcloseStatus = list[i].closeStatus;
              this.updateEditModeSet("edit", list[i].moDeliverOrderDetailListItem[j]);
            }

            for (var n = 0; n < list[i].inDetailList.length; n++) {
              list[i].inDetailList[n].createTime = list[i].inDetailList[n].createTime ? timeFormat(list[i].inDetailList[n].createTime) : "";
              list[i].inDetailList[n].voucherTypeName = list[i].inDetailList[n].voucherType === "ProductionIn" ? "生产入库" : "余料入库";
            }
          }
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    getFabricProduceDraftList() {
      // 面料生产申请草稿列表
      this.tableLoading = true;
      let type = "saleTool";
      if (this.disabledbo) {
        type = "finishedProduct";
      } else if (this.isMaterialsTransform) {
        type = "cover";
      }
      let data = {
        stStatus: "Draft",
        productionType: this.productionOrderType,
        productionOrderCode: this.productionOrderCode,
        productionMaterialCode: this.productionMaterialCode,
        beginTime: this.beginTime,
        endTime: this.endTime,
        productionOrderType: type
      };
      toolProduceDraftList(this.currentPage, this.pageSize, data).then(res => {
        console.log("销售工具生产草稿列表：", res);
        if (res.code === "0") {
          this.produceList = res.data.records;
          this.total = res.data.total;
          let list = this.produceList;
          for (var i = 0; i < list.length; i++) {
            list[i].createTime = list[i].createTime ? timeFormat(list[i].createTime) : "";
            list[i].status = list[i].stStatus;
            list[i].stStatusName = statusTypeHandle(list[i].stStatus);
            // 列表组件操作按钮
            // switch (list[i].stStatus) {
            //   case 'Draft':
            //     list[i].actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Rejected':
            //     list[i].actions = {
            //       hasEdit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Terminated':
            //     list[i].actions = {
            //       hasEdit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   default:
            //     list[i].actions = {};
            //     break;
            // }
            for (var j = 0; j < list[i].sampleStoriList.length; j++) {
              let data = list[i].sampleStoriList[j];
              this.updateEditModeSet("edit", data);
              for (var m = 0; m < data.sampleList.length; m++) {
                let cData = data.sampleList[m];
                cData.inventoryInNum = data.inventoryInNum;
                this.updateEditModeSet("edit", cData);
              }
            }
          }
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    getInventoryList() {
      // 仓位
      inventoryTypeList().then(res => {
        console.log("仓位列表数据：", res);
        if (res.code === "0") {
          this.outInventoryList = res.data;
          this.inInventoryList = res.data;
        }
      });
    },
    getSubInventoryList(id) {
      // 库位
      subInventoryTypeList("B0").then(res => {
        console.log("库位列表数据：", res);
        if (res.code === "0") {
          this.outInventoryListck = res.data;
          this.$forceUpdate();
        }
      });
    },
    // getMaterialClassList() {
    //   // 物料分类
    // 	materialClassList().then(res => {
    // 		if(res.code === '0') {
    // 			this.materialClassList = res.data;
    // 		}
    // 	});
    // },
    async getMaterialClassList() {
      let type = "CombinedMaterialClass";
      if (this.disabledbo || this.isMaterialsTransform) {
        type = "FinishedProductProduction";
      }
      // 生产类型
    let res = await constDictList(type)
        if (res.code === "0") {
          // this.materialClassList = res.data;
          if (this.isMaterialsTransform) {
            this.materialClassList = res.data.filter(x => {
              return x.name === "转换";
            });
          } else {
            this.materialClassList = res.data.filter(x => {
              return x.name !== "转换";
            });
          }
        }  
     },  
   async getSalesSeasonList() {
      // salesSeasonList().then(res => {
      //   console.log("销售季：", res);
      //   if (res.code === "0") {
      //     this.salesSeasonList = res.data;
      //   }
      // });
      let res = await salesSeasonList()
       this.salesSeasonList = res.data;
    },

    // 编辑组件函数
    updateEditModeSet(type, data, status) {
      // console.log(type, data, status)
      // 编辑字段呈现方式集合
      let editModeSet = JSON.parse(JSON.stringify(this.editModeSet));
      let textSet = JSON.parse(JSON.stringify(this.textSet));
      let inputSet = JSON.parse(JSON.stringify(this.inputSet));
      let selectSet = JSON.parse(JSON.stringify(this.selectSet));
      let numberInputSet = JSON.parse(JSON.stringify(this.numberInputSet));
      let datePickerSet = JSON.parse(JSON.stringify(this.datePickerSet));
      let unitSet = JSON.parse(JSON.stringify(this.unitSet));
      if (type == "edit") {
        switch (status) {
          case "Draft":
            break;
          case "Approved":
            break;
          default:
            break;
        }
      }
      editModeSet.textSet = textSet;
      editModeSet.inputSet = inputSet;
      editModeSet.selectSet = selectSet;
      editModeSet.numberInputSet = numberInputSet;
      editModeSet.datePickerSet = datePickerSet;
      editModeSet.unitSet = unitSet;
      data.editModeSet = editModeSet;
    },
    inputChange(val, item, fieldItem) {
      let editListCopy = JSON.parse(JSON.stringify(this.editList));
      switch (fieldItem.field) {
        case "productionMaterialCode":
          console.log("生产物料编号值改变：", val, item, fieldItem);
          for (var i = 0; i < editListCopy.length; i++) {
            let data = editListCopy[i];
            if (data.sampleUuid == item.sampleUuid) {
              getOriginCodeByMCV2(data.productionMaterialCode).then(res => {
                // console.log('生产物料编号请求数据：', res);
                if (res.code === "0" && res.data) {
                  // let pcArr = [];
                  // let mcArr = [];
                  // for (var m = 0; m < res.data.length; m++) {
                  //   pcArr.push(res.data[m].remarks);
                  //   mcArr.push(res.data[m].combinedMaterialCode);
                  // }
                  for (var j = 0; j < data.sampleList.length; j++) {
                    let cData = data.sampleList[j];
                    if (res.data != "") {
                      cData.sourceProductCode = res.data[j].remarks;
                      cData.sourceMaterialCode = res.data[j].combinedMaterialCode;
                    } else {
                      cData.sourceProductCode = "";
                      cData.sourceMaterialCode = "";
                    }
                    // cData.sourceProductCode = pcArr.join(',');
                    // cData.sourceMaterialCode = mcArr.join(',');
                    this.$forceUpdate();
                  }
                }
              });
            }
          }
          this.editList = editListCopy;
          this.$forceUpdate();
          break;
        case "productionQuantity":
          console.log("生产数量值改变：", val, item, fieldItem);
          if (val < 0) {
            item.productionQuantity = 0;
          }
          break;

        default:
          break;
      }
    },
    async selectChange(val, item, fieldItem) {
      console.log(val, item, fieldItem);
      let editListCopy = JSON.parse(JSON.stringify(this.editList));
      switch (fieldItem.field) {
        case "outVirtualInventoryName":
          // 出库仓位
          console.log("出库仓位值改变：", val, item, fieldItem);
          item.outSubInventoryList = [];
          let outSelected = this.outInventoryList.find(i => {
            return i.index === val;
          });
          // item.outVirtualInventoryCode = outSelected.index;
          // item.outVirtualInventoryName = outSelected.name;
          let outList = []; //库位列表数据
          await subInventoryTypeList(val).then(res => {
            if (res.code === "0") {
              outList = res.data;
            }
          });
          for (var i = 0; i < editListCopy.length; i++) {
            for (var j = 0; j < editListCopy[i].sampleList.length; j++) {
              let data = editListCopy[i].sampleList[j];
              if (data.uuid == item.uuid) {
                data.outVirtualInventoryCode = outSelected.index;
                data.outVirtualInventoryName = outSelected.name;
                data.outSubInventoryName = "";
                data.outSubInventoryList = outList;
              }
            }
          }
          this.editList = editListCopy;
          this.$forceUpdate();
          break;
        case "outSubInventoryName":
          // 出库库位
          console.log("出库库位值改变：", val, item, fieldItem);
          let outSelectedName = item.outSubInventoryList.find(i => {
            return i.subInventory === val;
          });
          item.outSubInventoryName = outSelectedName.subInventoryName;
          item.outSubInventoryCode = val;
          break;
        case "inVirtualInventoryName":
          // 入库仓位
          console.log("入库仓位值改变：", val, item, fieldItem);
          item.inSubInventoryList = [];
          let inSelected = this.inInventoryList.find(i => {
            return i.index === val;
          });
          // item.inVirtualInventoryName = inSelected.name;
          // item.inVirtualInventoryCode = inSelected.index;
          let inList = []; //库位列表数据
          await subInventoryTypeList(val).then(res => {
            if (res.code === "0") {
              inList = res.data;
            }
          });
          item.inVirtualInventoryName = inSelected.name;
          item.inVirtualInventoryCode = inSelected.index;
          item.inSubInventoryName = "";
          item.inSubInventoryList = inList;

          // for (var i = 0; i < editListCopy.length; i++) {
          //   for (var j = 0; j < editListCopy[i].sampleList.length; j++) {
          //     let data = editListCopy[i].sampleList[j];
          //     if (data.uuid == item.uuid) {
          //       data.inVirtualInventoryName = inSelected.name;
          //       data.inVirtualInventoryCode = inSelected.index;
          //       data.inSubInventoryName = '';
          //       data.inSubInventoryList = inList;
          //     }
          //   }
          // }
          // this.editList = editListCopy;
          this.$forceUpdate();
          break;
        case "inSubInventoryName":
          // 入库库位
          console.log("入库库位值改变：", val, item, fieldItem);
          let inSelectedName = item.inSubInventoryList.find(i => {
            return i.subInventory === val;
          });
          item.inSubInventoryName = inSelectedName.subInventoryName;
          item.inSubInventoryCode = val;
          break;
        default:
          break;
      }
    },
    async selectVisibleChange(val, item, fieldItem) {
      let editListCopy = JSON.parse(JSON.stringify(this.editList));
      switch (fieldItem.field) {
        case "outSubInventoryName":
          console.log("出库库位值显隐：", val, item, fieldItem);
          let outList = []; // 库位列表数据
          await subInventoryTypeList(item.outVirtualInventoryCode).then(res => {
            if (res.code === "0") {
              outList = res.data;
            }
          });
          for (var i = 0; i < editListCopy.length; i++) {
            for (var j = 0; j < editListCopy[i].sampleList.length; j++) {
              let data = editListCopy[i].sampleList[j];
              if (data.uuid === item.uuid) {
                data.outSubInventoryList = outList;
              }
            }
          }
          this.editList = editListCopy;
          this.$forceUpdate();

          // subInventoryTypeList(item.outVirtualInventoryCode).then(res => {
          //   console.log('出库库位显隐：', res);
          //   if (res.code === '0') {
          //     item.outSubInventoryList = res.data;
          //     this.$forceUpdate();
          //   }
          // });
          break;
        case "inSubInventoryName":
          console.log("入库库位值显隐：", val, item, fieldItem);
          let inList = []; // 库位列表数据
          await subInventoryTypeList(item.inVirtualInventoryCode).then(res => {
            if (res.code === "0") {
              inList = res.data;
            }
          });
          for (var i = 0; i < editListCopy.length; i++) {
            let data = editListCopy[i];
            if (data.sampleUuid === item.sampleUuid) {
              data.inSubInventoryList = inList;
            }
          }
          this.editList = editListCopy;
          this.$forceUpdate();

          // subInventoryTypeList(item.inVirtualInventoryCode).then(res => {
          //   if (res.code === '0') {
          //     item.inSubInventoryList = res.data;
          //     this.$forceUpdate();
          //   }
          // });
          break;

        default:
          break;
      }
    },
    numberInputChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case "packetNumber":
          // 计划预留数量
          console.log("计划预留数量值改变：", val, item, fieldItem);
          break;
        case "shippmentMeter":
          // 在途
          console.log("在途值改变：", val, item, fieldItem);
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          break;
        case "producedMeter":
          // 成品
          console.log("成品值改变：", val, item, fieldItem);
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          break;
        case "producingMeter":
          // 在产
          console.log("在产值改变：", val, item, fieldItem);
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          break;
        case "requestedMeter":
          // 询单
          console.log("询单值改变：", val, item, fieldItem);
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          break;

        default:
          break;
      }
    },
    datePickerChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case "reserveExpectDate":
          console.log("期望交期值改变：", val, item, fieldItem);
          break;
        case "reserveRealDate":
          console.log("预计实际交期值改变：", val, item, fieldItem);
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";

* {
  box-sizing: border-box;
}

.container {
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 6px;
}

.productionOrder {
  padding: 10px 15px;

  .actions_part {
    margin: 10px 0;
  }

  .edit_wrap {
    overflow: auto;

    .childlist {
      width: 100%;
    }

    .edit_parent_list {
      width: 100%;

      .edit_item {
        position: relative;
        border-bottom: 1px solid #eeeeee;
      }

      .add_btn {
        position: absolute;
        bottom: 14px;
        right: 2%;
      }

      ul {
        &.childcontentlist {
          border-bottom: none;
        }
      }

      // .edit_btn_wrap {
      //   margin-top: 20px;
      //   margin-bottom: 20px;
      // }
    }

    .meters {
      width: 150px;
      height: 32px;
      line-height: 32px;
      text-align: left;
      vertical-align: top;

      .count {
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .pagination_wrap {
    margin-top: 20px;
  }
}

.table_part,
.popup_part {
  position: relative;

  ul {
    &.childcontentlist {
      height: 60px;

      li {
        line-height: 40px;
      }
    }
  }

  .list_group {
    border-bottom: 1px solid #eeeeee;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .empty_wrap {
    padding: 20px 0;
    text-align: center;
  }
}

.subnav_wrap .el-checkbox {
  white-space: nowrap;
}

i.el-icon-remove,
i.el-icon-circle-plus {
  font-size: 28px;
  color: red;
  cursor: pointer;
}

i.el-icon-circle-plus {
  color: #409eff;
}
</style>
<style>
.productionOrder .subnav_wrap .el-tabs__item {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
  vertical-align: baseline !important;
}

.productionOrder .subnav_wrap.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
}

.productionOrder .subnav_wrap .el-tabs__content {
  overflow: visible;
}

.productionOrder .subnav_wrap .checkbox_list .el-checkbox__label {
  width: 100%;
  padding-left: 0;
  color: #606266;
}

.productionOrder .subnav_wrap .checkbox_list .el-checkbox {
  width: 100%;
}

.productionOrder .subnav_wrap .checkbox_list .el-checkbox__input {
  margin-right: 10px;
}
.el-popper[x-placement^=bottom] {
    top: 180px !important;
    /* height:50%; */
}
.el-popper[x-placement^=bottom] .el-cascader-panel{
    height: 100%;
}
</style>